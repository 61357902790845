import { useState, useEffect, createRef } from "react";
import theme from "../../theme/theme";
import SearchIcon from "../../assets/searchIcon.svg";
import CloseIcon from "../../assets/closeIcon.svg";
import DashboardListItems from "../DashboardListItems";
import SearchBox from "../../elements/SearchBox";
import useTranslation from "../../localization/translations";
import useStyles from "./styles";
import InfoDialog from "components/InfoDialog";

const DashboardList: React.FC<any> = (props) => {
  const {
    dashboardData,
    setSelectedMarker,
    selectedMarker,
    setSelectedNotification,
    selectedNotification,
    notificationTimeStamp,
    handleViewDialogue,
  } = props;
  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const [searchOpen, setSearchOpen] = useState<any>(false);

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const {
    dashboardRightPanel,
    dashboarListTitle,
    listTitleName,
    listSearch,
    dashboardListSection,
    searchClass,
    noResultStyle,
  } = useStyles(appTheme);

  const { dashboardListName, search, noResult } = useTranslation();

  const [searchValue, setSearchValue] = useState<any>(dashboardData);
  const [selectedRefId, setSelectedRefId] = useState("");

  const handleSearch = (searchValue: any) => {
    let searchResult = dashboardData?.filter((value: any) => {
      return (
        value?.title
          ?.toLowerCase()
          .includes(searchValue?.toString()?.toLowerCase()) ||
        value?.area
          ?.toLowerCase()
          .includes(searchValue?.toString()?.toLowerCase())
      );
    });

    setSearchValue(searchResult);
    setSelectedNotification(-1);
    setSearchOpen(true);
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
    setSearchValue(dashboardData);
    setSelectedNotification(-1);
  };

  const handleExpandListItem = (index: number, id: any, dateTime: any) => {
    setSelectedNotification(selectedNotification === id ? -1 : id);
    setSelectedRefId(id);
  };

  const refs =
    searchValue && searchValue.length > 0
      ? searchValue.reduce((acc: any, value: any) => {
          acc[value.id] = createRef<any>();
          return acc;
        }, {})
      : "";

  // refID - Marker Ref ID,  selectedRefId - list Ref ID

  useEffect(() => {
    if ((selectedMarker || selectedRefId) && refs) {
      setTimeout(() => {
        refs[
          selectedMarker ? selectedMarker : selectedRefId
        ]?.current?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }, 300);
    }
  }, [refs, selectedRefId, selectedMarker]);

  // Marker Click

  // useEffect(() => {
  //   if ((selectedMarker : any || selectedRefId : any ) && refs) {
  //     setTimeout(() => {
  //       refs[selectedMarker ? selectedMarker : selectedRefId]?.current?.scrollIntoView({
  //         behavior: "smooth",
  //         block: "nearest",
  //       });
  //     }, 300);
  //   }
  // }, [selectedMarker, refs, selectedRefId]);

  return (
    <>
      <div className={dashboardRightPanel}>
        <div className={dashboarListTitle}>
          <div className={listTitleName}>
            {!searchOpen ? (
              `${dashboardListName}`
            ) : (
              <SearchBox
                searchInput={searchClass}
                placeHolder={search}
                handleSearch={handleSearch}
                searchIsOpen={true}
                fontColor={appTheme?.palette?.dashboardList?.darkGrey3}
              />
            )}
          </div>
          <div className={listSearch}>
            <img
              src={searchOpen ? CloseIcon : SearchIcon}
              alt="search"
              onClick={searchOpen ? handleSearchClose : handleSearch}
            />
          </div>
        </div>
        <div className={dashboardListSection}>
          {searchValue && searchValue?.length > 0 ? (
            searchValue.map((item: any, index: number) => {
              return (
                <DashboardListItems
                  refs={refs}
                  data={item}
                  key={index}
                  handleExpandListItem={handleExpandListItem}
                  index={index}
                  selectedNotification={selectedNotification}
                  handleViewDialogue={handleViewDialogue}
                  notificationTimeStamp={notificationTimeStamp}
                />
              );
            })
          ) : (
            <div className={noResultStyle}>{noResult}</div>
          )}
        </div>
      </div>
    </>
  );
};
export default DashboardList;
