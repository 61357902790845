import React, { Fragment, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import theme from "../../theme/theme";
import EquipmentsList from "components/EquipmentsList";
import CardsList from "components/CardsList";
import configurationsList from "mockdata/configurationsList";
import ConfigurationImage from "../../assets/configurationImage.svg";
import useStyles from "./styles";

const ConfigurationContainer: React.FC = (props: any) => {
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const { configurationImage, configureTitle } = useStyles(appTheme);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  const [currentIndex, setCurrentIndex] = useState();
  const [configurationData, setConfigurationData] = useState([]);
  const [currentListItemIndex, setCurrentListItemIndex] = useState<any>(0);

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("configurationData") || "[]");
    if (data?.length > 0) {
      localStorage.setItem("configurationData", JSON.stringify(data));
    } else {
      localStorage.setItem(
        "configurationData",
        JSON.stringify(configurationsList)
      );
    }

    if (data?.length > 0) {
      setConfigurationData(data);
    }
  }, []);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("configurationData") || "[]");
    if (data?.length > 0) {
      setConfigurationData(data);
    }
  }, []);

  const handleSelectedIndex = (id: any) => {
    setCurrentIndex(id);
  };

  const handleSelectedListItemIndex = (index: any) => {
    setCurrentListItemIndex(index);
  };

  return (
    <Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={3} lg={4} xl={3}>
            <EquipmentsList
              equipmentsList={configurationData}
              handleSelectedIndex={handleSelectedIndex}
              handleSelectedListItemIndex={handleSelectedListItemIndex}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={9} lg={8} xl={9}>
            {currentIndex === undefined || currentIndex === null ? (
              <div className={configurationImage}>
                <img src={ConfigurationImage} />
                <div className={configureTitle}>
                  Click on an Equipment to configure
                </div>
              </div>
            ) : (
              <CardsList
                selectedIndex={currentIndex}
                currentListItemIndex={currentListItemIndex}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default ConfigurationContainer;
