import { useState, useEffect, Fragment } from "react";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import theme from "../../theme/theme";
import AlertsList from "components/AlertsList";
import FloorMap from "../FloorMap";
import DashboardMap from "components/DashboardMap";
import DashboardList from "../DashboardList";
import dashBoardEquipments from "../../mockdata/dashBoardEquipments";
import InfoIcon from "../../elements/InfoIcon";
import AlertIcon from "../../assets/InfoIcon/alert.svg";
import SafetyIcon from "../../assets/InfoIcon/safety.svg";
import LastMaintanenceIcon from "../../assets/InfoIcon/lastMaintanence.svg";
import NextMaintanenceIcon from "../../assets/InfoIcon/nextMaintanence.svg";
import ClockIcon from "../../assets/InfoIcon/clock.svg";
import EnergyIcon from "../../assets/InfoIcon/energy.svg";
import PalletsIcon from "../../assets/InfoIcon/pallets.svg";
import WorkersIcon from "../../assets/InfoIcon/workers.svg";
import PowerBackupIcon from "../../assets/InfoIcon/powerBackup.svg";
import ProductionIcon from "../../assets/InfoIcon/production.svg";
import alertsListData from "mockdata/alertsListData";
import useStyles from "./styles";

const AlertsContainer: React.FC<any> = (props) => {
  const {} = props;

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const {
    alertsContainerMain,
    infoIconContainer,
    floorMapContainerStyle,
    fullscreenMapStyle,
    fullscreenMapInnerStyle,
  } = useStyles(appTheme);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );
  const [selectedNotification, setSelectedNotification] = useState<any>(-1);
  const [alertsMainData, setAlertsMainData] = useState();
  const [showFullscreenMap, setShowFullscreenMap] = useState(false);
  const [tabIndex, setTabIndex] = useState<number>(1);

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const alertsData = alertsListData?.alertsList;

  useEffect(() => {
    const { events, alerts, operations } = alertsListData?.alertsList;
    const combinedNotifications: any = [];

    events?.forEach((event, index) => {
      combinedNotifications.push({ ...event, type: "events" });
    });

    alerts?.forEach((alerts, index) => {
      combinedNotifications.push({ ...alerts, type: "alerts" });
    });

    operations?.forEach((operations, index) => {
      combinedNotifications.push({ ...operations, type: "operations" });
    });

    const dataValue: any = combinedNotifications?.map(
      (value: any, index: number) => {
        return { ...value, id: index + 1, index: index + 1 };
      }
    );

    setNotifications(dataValue);
  }, []);

  //   const dashboardListData: any = dashBoardEquipments?.equipments;

  const [alertsMainList, setAlertsMainList] = useState(alertsListData);

  // useEffect(() => {
  //   const dataValue: any = notifications?.map((value: any, index: number) => {
  //     return { ...value, indexVal: index };
  //   });
  //   setNotifications(dataValue);
  // }, []);

  //   useEffect(() => {}, [dashboardEquipmentData]);

  let currentTimeStampValue;
  let timeArrayNew: any = [];
  for (let i = 0; i < notifications?.length; i++) {
    currentTimeStampValue = moment()
      .subtract({
        hours: i === 0 ? i : i > 20 ? 20 : i + 1,
        minutes: i + 59,
        seconds: i + 49,
      })
      .format("MM-DD-YYYY | h:mm A");
    timeArrayNew.push({ currentTimeStamp: currentTimeStampValue });
  }

  let consolidatedData = timeArrayNew?.map((item: any, i: any) =>
    Object.assign({}, item, notifications[i])
  );

  const infoIconData = alertsListData?.infoList;

  const infoIconArray = [
    {
      value: infoIconData && infoIconData[0]?.value,
      title: infoIconData && infoIconData[0]?.title,
      color: appTheme?.palette?.dashboardList?.lightPurple1,
      icon: SafetyIcon,
    },
    {
      value: infoIconData && infoIconData[1]?.value,
      title: infoIconData && infoIconData[1]?.title,
      color: appTheme?.palette?.dashboardList?.lightBlue3,
      icon: EnergyIcon,
    },
    {
      value: infoIconData && infoIconData[2]?.value,
      title: infoIconData && infoIconData[2]?.title,
      color: "#62ACFF",
      icon: PowerBackupIcon,
    },
    {
      value: moment().subtract(10, "d").format("MMM DD, YYYY"),
      title: infoIconData && infoIconData[3]?.title,
      color: appTheme?.palette?.dashboardList?.orange,
      icon: LastMaintanenceIcon,
    },
    {
      value: moment().add(5, "d").format("MMM DD, YYYY"),
      title: infoIconData && infoIconData[4]?.title,
      color: appTheme?.palette?.dashboardList?.greenishBlue,
      icon: NextMaintanenceIcon,
    },
  ];

  const [notificationTimeStamp, setNotificationTimeStamp] = useState();

  // useEffect(() => {
  //   if (selectedNotification || selectedNotification) {
  //     for (let i = 0; i <= consolidatedData?.length; i++) {
  //       if (
  //         consolidatedData[i]?.id ===
  //         (selectedNotification || selectedNotification)
  //       ) {
  //         setNotificationTimeStamp(consolidatedData[i]?.currentTimeStamp);
  //       }
  //     }
  //   }
  // }, [selectedNotification, selectedNotification, notificationTimeStamp]);

  return (
    <Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container className={alertsContainerMain}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={9.2}>
            <div className={floorMapContainerStyle}>
              <FloorMap
                floorWidth={1338.48}
                floorHeight={777.13}
                markers={notifications}
                selectedMarker={selectedNotification}
                setSelectedMarker={setSelectedNotification}
                setSelectedNotification={setSelectedNotification}
                selectedNotification={selectedNotification}
                setShowFullscreenMap={setShowFullscreenMap}
                showFullscreenMap={showFullscreenMap}
                notificationTimeStamp={notificationTimeStamp}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
              />
              <div>
                <div className={infoIconContainer}>
                  {infoIconArray &&
                    infoIconArray.length > 0 &&
                    infoIconArray?.map((value, index) => {
                      return (
                        <InfoIcon
                          color={value?.color}
                          title={value?.value}
                          tagLine={value?.title}
                          icon={value?.icon}
                          fabWidth={45}
                          fabHeight={45}
                          imgWidth={25}
                          imgHeight={25}
                          key={index}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={2.8}>
            {consolidatedData && consolidatedData?.length > 0 ? (
              <AlertsList
                alertsData={alertsData}
                consolidatedData={consolidatedData}
                selectedMarker={selectedNotification}
                // setSelectedMarker={setSelectedNotification}
                setSelectedNotification={setSelectedNotification}
                selectedNotification={selectedNotification}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                setNotificationTimeStamp={setNotificationTimeStamp}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Box>
      {showFullscreenMap ? (
        <div className={fullscreenMapStyle}>
          <div className={fullscreenMapInnerStyle}>
            <FloorMap
              floorWidth={1338.48}
              floorHeight={777.13}
              markers={notifications}
              selectedMarker={selectedNotification}
              setSelectedMarker={setSelectedNotification}
              setSelectedNotification={setSelectedNotification}
              selectedNotification={selectedNotification}
              setShowFullscreenMap={setShowFullscreenMap}
              showFullscreenMap={showFullscreenMap}
              notificationTimeStamp={notificationTimeStamp}
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
            />
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};
export default AlertsContainer;
