import { useState, useEffect, Fragment } from "react";
import theme from "theme/theme";
import ChevronRightIcon from "../../assets/chevronRightIcon.svg";
import ChevronUpIcon from "../../assets/chevronUpIcon.svg";
import useStyles from "./styles";

const EquipmentsListItems = (props: any) => {
  const { equipmentsList } = props;

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  const [currentIndex, setCurrentIndex] = useState<any>(undefined);
  const [currentItemExpand, setCurrentItemExpand] = useState<any>(false);
  const [currentListItemIndex, setCurrentListItemIndex] = useState<any>(0);

  const { rootContainer, listContainer, listitem, itemListTitle } =
    useStyles(appTheme);

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  useEffect(() => {
    props.handleSelectedIndex(currentIndex);
  }, []);

  const handleListClick = (event: any, index: any, id: any) => {
    if (currentIndex === id) {
      setCurrentIndex(null);
      setCurrentItemExpand(null);
      props.handleSelectedIndex(null);
    } else {
      setCurrentIndex(id);
      setCurrentItemExpand(id);
      props.handleSelectedIndex(id);
    }
    props.handleSelectedListItemIndex(0);
    setCurrentListItemIndex(0);
  };

  const handleListItemClick = (event: any, index: any, id: any) => {
    setCurrentListItemIndex(index);
    props.handleSelectedListItemIndex(index);
  };

  return (
    <>
      {equipmentsList?.map((item: any, index: number) => {
        return (
          <Fragment key={index}>
            <div
              className={rootContainer}
              key={index}
              onClick={(e) => handleListClick(e, index, item?.id)}
              style={{
                background: item?.id === currentIndex ? "#3253DC" : "#FFFFFF",
                color: item?.id === currentIndex ? "#FFFFFF" : "#323232",
              }}
            >
              <div className={itemListTitle}>{item?.title}</div>
              <div>
                <img
                  src={
                    item?.id === currentIndex ? ChevronUpIcon : ChevronRightIcon
                  }
                />
              </div>
            </div>
            {currentItemExpand === item?.id && (
              <div className={listContainer}>
                {item?.subList?.map((itemList: any, idx: any) => {
                  return (
                    <div
                      className={listitem}
                      key={idx}
                      onClick={(e) => handleListItemClick(e, idx, itemList?.id)}
                      style={{
                        background:
                          idx === currentListItemIndex ? "#8C9BE8" : "#FFFFFF",
                        color:
                          idx === currentListItemIndex ? "#FFFFFF" : "#323232",
                      }}
                    >
                      <div className={itemListTitle}>{itemList?.title}</div>
                    </div>
                  );
                })}
              </div>
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default EquipmentsListItems;
