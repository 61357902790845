import colorCodes from "./colors";

let theme = {
  defaultTheme: {
    palette: {
      login: {
        pageBackgroundColor: "#300254",
        loginBtnColor: "",
        titleColor: "",
        forgotPwdColor: "",
        loginBannerTitle: colorCodes.colorWhite,
        loginFormBg: colorCodes.colorWhite,
        loginBannerDescription: colorCodes.colorWhite,
        welcomeContent: colorCodes.welcomeContent,
        loginFormTitle: colorCodes.loginFormTitle,
        inputTitleColor: colorCodes.inputTitleColor,
        inputPlaceholder: colorCodes.inputPlaceholder,
        copyRight: colorCodes.darkBlack1,
        loginButton: colorCodes.blue,
        atthirate: colorCodes.lightBlue,
        logoBg: colorCodes.darkBlack,
      },
      dashboard: { pageBackgroundColor: colorCodes.darkBlueThemeColor },

      dashboardList: {
        darkGrey2: colorCodes.darkGrey2,
        lightGrey: colorCodes.lightGrey,
        darkGreen: colorCodes.darkGreen2,
        lightPurple: colorCodes.lightPurple,
        darkGrey: colorCodes.darkGrey,
        green: colorCodes.colorGreen,
        red: colorCodes.colorRed,
        orange: colorCodes.colorOrange,
        white: colorCodes.colorWhite,
        darkPurple: colorCodes.darkPurple,
        darkGrey3: colorCodes.darkGrey3,
        darkGrey4: colorCodes.darkGrey4,
        black: colorCodes.colorBlack,
        lightPurple2: colorCodes.lightPurple2,
        lightGrey1: colorCodes.lightGrey1,
        lightGrey2: colorCodes.lightGrey2,
        lightGrey3: colorCodes.lightGrey3,
        lightBlue1: colorCodes.lightBlue4,
        darkBlue2: colorCodes.darkBlue2,
        lightBlue2: colorCodes.lightBlue2,
        darkRedBlack: colorCodes.darkRedBlack,
        lightRedBlack: colorCodes.lightRedBlack,
        darkRedBlack2: colorCodes.darkRedBlack2,
        blue: colorCodes.blue,
        lightPurple1: colorCodes.lightPurple1,
        lightBlue3: colorCodes.lightBlue5,
        lightGreen: colorCodes.lightGreen,
        greenishBlue: colorCodes.greenishBlue,
        blue1: colorCodes.blue1,
        darkPink: colorCodes.darkPink,
        black1: colorCodes.black1,
        lightSkyBlue2: colorCodes.lightSkyBlue2,
        darkBlackRgba1: colorCodes.darkBlackRgba1,
      },

      profile: {
        pageBackgroundColor: "#300254",
        lightGrey: colorCodes.lightGrey,
        darkGrey: colorCodes.darkGrey,
      },
      header: {
        darkBlueHeader: colorCodes.darkQualcomBlue,
        colorWhite: colorCodes.colorWhite,
        avatarBgColorViolet: colorCodes.lightSkyBlue,
        darkGrayLogoutText: colorCodes.darkGray1,
        lightGrayLogoutText: colorCodes.lightGray,
        darkGreenHeaderButton: colorCodes.darkGreen,
        disableGrayHeaderButtonText: colorCodes.disableGray,
        indicatorColor: colorCodes.lightBlue1,
        inactiveTabColor: colorCodes.lightBlue3,
        lightSkyBlue1: colorCodes.lightSkyBlue1,
      },
      buttonElement: {
        darkBlue1: colorCodes.darkBlue1,
        deepDarkBlue: colorCodes.deepDarkBlue,
        paleGray: colorCodes.paleGray,
        colorWhite: colorCodes.colorWhite,
      },

      infoSubListElement: {
        lightShadeGray: colorCodes.lightShadeGray,
        lightShadeGray1: colorCodes.lightShadeGray1,
        colorWhite: colorCodes.colorWhite,
      },

      infoDialogueComponent: {
        darkGrayShades: colorCodes.darkGrayShades,
        greenBlackShades: colorCodes.greenBlackShades,
        blackShades: colorCodes.blackShades,
        colorWhite: colorCodes.colorWhite,
        lightShadeGray: colorCodes.lightShadeGray,
        lightGray: colorCodes.lightGray,
        maroonShades: colorCodes.maroonShades,
        navyBlue: colorCodes.navyBlue,
        orangeShade: colorCodes.orangeShade,
        greenShade1: colorCodes.greenShade1,
        darkBlack2: colorCodes.darkBlack2,
      },
      chipButtonElement: {
        greyShade: colorCodes.greyShade,
        whiteShade: colorCodes.whiteShade,
        colorWhite: colorCodes.colorWhite,
      },
      infoAlertListItemComponent: {
        colorBlack: colorCodes.colorBlack,
        colorWhite: colorCodes.colorWhite,
      },
      searchBoxElement: {
        colorWhite: colorCodes.colorWhite,
      },
      selectElement: {
        colorWhite: colorCodes.colorWhite,
        colorBlack: colorCodes.colorBlack,
        blackShade: colorCodes.blackShade,
        orangeShade1: colorCodes.orangeShade1,
      },
      chartElement: {
        colorWhite: colorCodes.colorWhite,
        grayShade: colorCodes.grayShade,
        lightGrayShade: colorCodes.lightGrayShade,
        lightBlueShade: colorCodes.lightBlueShade,
        blueShade: colorCodes.blueShade,
        blueDarkShade: colorCodes.blueDarkShade,
        maroonShades: colorCodes.maroonShades,
        navyBlue: colorCodes.navyBlue,
        orangeShade: colorCodes.orangeShade,
        greenShade1: colorCodes.greenShade1,
      },
    },
    spacing: 5,
    typography: { fontFamily: "Arial" },
  },
  greenTheme: {
    palette: {
      login: {
        pageBackgroundColor: "#68F73A",
        loginBtnColor: "",
        titleColor: "",
        forgotPwdColor: "",
        loginBannerTitle: colorCodes.colorWhite,
        loginFormBg: colorCodes.colorWhite,
        loginBannerDescription: colorCodes.colorWhite,
        welcomeContent: colorCodes.welcomeContent,
        loginFormTitle: colorCodes.loginFormTitle,
        inputTitleColor: colorCodes.inputTitleColor,
        inputPlaceholder: colorCodes.inputPlaceholder,
        copyRight: colorCodes.darkBlack1,
        loginButton: colorCodes.blue,
        atthirate: colorCodes.lightBlue,
        logoBg: colorCodes.darkBlack,
      },
      dashboard: { pageBackgroundColor: colorCodes.greenThemeColor },

      dashboardList: {
        darkGrey2: colorCodes.darkGrey2,
        lightGrey: colorCodes.lightGrey,
        darkGreen: colorCodes.darkGreen2,
        lightPurple: colorCodes.lightPurple,
        darkGrey: colorCodes.darkGrey,
        green: colorCodes.colorGreen,
        red: colorCodes.colorRed,
        orange: colorCodes.colorOrange,
        white: colorCodes.colorWhite,
        darkPurple: colorCodes.darkPurple,
        darkGrey3: colorCodes.darkGrey3,
        darkGrey4: colorCodes.darkGrey4,
        black: colorCodes.colorBlack,
        lightPurple2: colorCodes.lightPurple2,
        lightGrey1: colorCodes.lightGrey1,
        lightGrey2: colorCodes.lightGrey2,
        lightGrey3: colorCodes.lightGrey3,
        lightBlue1: colorCodes.lightBlue4,
        darkBlue2: colorCodes.darkBlue2,
        lightBlue2: colorCodes.lightBlue2,
        darkRedBlack: colorCodes.darkRedBlack,
        lightRedBlack: colorCodes.lightRedBlack,
        darkRedBlack2: colorCodes.darkRedBlack2,
        blue: colorCodes.blue,
        lightPurple1: colorCodes.lightPurple1,
        lightBlue3: colorCodes.lightBlue5,
        lightGreen: colorCodes.lightGreen,
        greenishBlue: colorCodes.greenishBlue,
        blue1: colorCodes.blue1,
        darkPink: colorCodes.darkPink,
        black1: colorCodes.black1,
        lightSkyBlue2: colorCodes.lightSkyBlue2,
        darkBlackRgba1: colorCodes.darkBlackRgba1,
      },
      profile: {
        pageBackgroundColor: "#68F73A",
        lightGrey: colorCodes.lightGrey,
        darkGrey: colorCodes.darkGrey,
      },
      header: {
        darkBlueHeader: colorCodes.greenThemeColor,
        colorWhite: colorCodes.colorWhite,
        avatarBgColorViolet: colorCodes.lightSkyBlue,
        darkGrayLogoutText: colorCodes.darkGray1,
        lightGrayLogoutText: colorCodes.lightGray,
        darkGreenHeaderButton: colorCodes.blueDarkColor,
        disableGrayHeaderButtonText: colorCodes.disableGray,
        indicatorColor: colorCodes.lightBlue1,
        inactiveTabColor: colorCodes.lightBlue3,
        lightSkyBlue1: colorCodes.lightSkyBlue1,
      },
      buttonElement: {
        darkBlue1: colorCodes.darkBlue1,
        deepDarkBlue: colorCodes.deepDarkBlue,
        paleGray: colorCodes.paleGray,
        colorWhite: colorCodes.colorWhite,
      },

      infoSubListElement: {
        lightShadeGray: colorCodes.lightShadeGray,
        lightShadeGray1: colorCodes.lightShadeGray1,
        colorWhite: colorCodes.colorWhite,
      },

      infoDialogueComponent: {
        darkGrayShades: colorCodes.darkGrayShades,
        greenBlackShades: colorCodes.greenBlackShades,
        blackShades: colorCodes.blackShades,
        colorWhite: colorCodes.colorWhite,
        lightShadeGray: colorCodes.lightShadeGray,
        lightGray: colorCodes.lightGray,
        maroonShades: colorCodes.maroonShades,
        navyBlue: colorCodes.navyBlue,
        orangeShade: colorCodes.orangeShade,
        greenShade1: colorCodes.greenShade1,
        darkBlack2: colorCodes.darkBlack2,
      },
      chipButtonElement: {
        greyShade: colorCodes.greyShade,
        whiteShade: colorCodes.whiteShade,
        colorWhite: colorCodes.colorWhite,
      },
      infoAlertListItemComponent: {
        colorBlack: colorCodes.colorBlack,
        colorWhite: colorCodes.colorWhite,
      },
      searchBoxElement: {
        colorWhite: colorCodes.colorWhite,
      },
      selectElement: {
        colorWhite: colorCodes.colorWhite,
        colorBlack: colorCodes.colorBlack,
        blackShade: colorCodes.blackShade,
        orangeShade1: colorCodes.orangeShade1,
      },
      chartElement: {
        colorWhite: colorCodes.colorWhite,
        grayShade: colorCodes.grayShade,
        lightGrayShade: colorCodes.lightGrayShade,
        lightBlueShade: colorCodes.lightBlueShade,
        blueShade: colorCodes.blueShade,
        blueDarkShade: colorCodes.blueDarkShade,
        maroonShades: colorCodes.maroonShades,
        navyBlue: colorCodes.navyBlue,
        orangeShade: colorCodes.orangeShade,
        greenShade1: colorCodes.greenShade1,
      },
    },
    spacing: 5,
    typography: { fontFamily: "Arial" },
  },
  yellowTheme: {
    palette: {
      login: {
        pageBackgroundColor: "#D1F557",
        loginBtnColor: "",
        titleColor: "",
        forgotPwdColor: "",
        loginBannerTitle: colorCodes.colorBlack,
        loginFormBg: colorCodes.colorWhite,
        loginBannerDescription: colorCodes.colorWhite,
        welcomeContent: colorCodes.welcomeContent,
        loginFormTitle: colorCodes.loginFormTitle,
        inputTitleColor: colorCodes.inputTitleColor,
        inputPlaceholder: colorCodes.inputPlaceholder,
        copyRight: colorCodes.darkBlack1,
        loginButton: colorCodes.blue,
        atthirate: colorCodes.lightBlue,
        logoBg: colorCodes.darkBlack,
      },
      dashboard: { pageBackgroundColor: colorCodes.yellowThemeColor },

      dashboardList: {
        darkGrey2: colorCodes.darkGrey2,
        lightGrey: colorCodes.lightGrey,
        darkGreen: colorCodes.darkGreen2,
        lightPurple: colorCodes.lightPurple,
        darkGrey: colorCodes.darkGrey,
        green: colorCodes.colorGreen,
        red: colorCodes.colorRed,
        orange: colorCodes.colorOrange,
        white: colorCodes.colorWhite,
        darkPurple: colorCodes.darkPurple,
        darkGrey3: colorCodes.darkGrey3,
        darkGrey4: colorCodes.darkGrey4,
        black: colorCodes.colorBlack,
        lightPurple2: colorCodes.lightPurple2,
        lightGrey1: colorCodes.lightGrey1,
        lightGrey2: colorCodes.lightGrey2,
        lightGrey3: colorCodes.lightGrey3,
        lightBlue1: colorCodes.lightBlue4,
        darkBlue2: colorCodes.darkBlue2,
        lightBlue2: colorCodes.lightBlue2,
        darkRedBlack: colorCodes.darkRedBlack,
        lightRedBlack: colorCodes.lightRedBlack,
        darkRedBlack2: colorCodes.darkRedBlack2,
        blue: colorCodes.blue,
        lightPurple1: colorCodes.lightPurple1,
        lightBlue3: colorCodes.lightBlue5,
        lightGreen: colorCodes.lightGreen,
        greenishBlue: colorCodes.greenishBlue,
        blue1: colorCodes.blue1,
        darkPink: colorCodes.darkPink,
        black1: colorCodes.black1,
        lightSkyBlue2: colorCodes.lightSkyBlue2,
        darkBlackRgba1: colorCodes.darkBlackRgba1,
      },
      profile: {
        pageBackgroundColor: "#D1F557",
        lightGrey: colorCodes.lightGrey,
        darkGrey: colorCodes.darkGrey,
      },
      header: {
        darkBlueHeader: colorCodes.yellowThemeColor,
        colorWhite: colorCodes.colorWhite,
        avatarBgColorViolet: colorCodes.lightSkyBlue,
        darkGrayLogoutText: colorCodes.darkGray1,
        lightGrayLogoutText: colorCodes.lightGray,
        darkGreenHeaderButton: colorCodes.darkGreen,
        disableGrayHeaderButtonText: colorCodes.disableGray,
        indicatorColor: colorCodes.lightBlue1,
        inactiveTabColor: colorCodes.lightBlue3,
        lightSkyBlue1: colorCodes.lightSkyBlue1,
      },
      buttonElement: {
        darkBlue1: colorCodes.darkBlue1,
        deepDarkBlue: colorCodes.deepDarkBlue,
        paleGray: colorCodes.paleGray,
        colorWhite: colorCodes.colorWhite,
      },

      infoSubListElement: {
        lightShadeGray: colorCodes.lightShadeGray,
        lightShadeGray1: colorCodes.lightShadeGray1,
        colorWhite: colorCodes.colorWhite,
      },

      infoDialogueComponent: {
        darkGrayShades: colorCodes.darkGrayShades,
        greenBlackShades: colorCodes.greenBlackShades,
        blackShades: colorCodes.blackShades,
        colorWhite: colorCodes.colorWhite,
        lightShadeGray: colorCodes.lightShadeGray,
        lightGray: colorCodes.lightGray,
        maroonShades: colorCodes.maroonShades,
        navyBlue: colorCodes.navyBlue,
        orangeShade: colorCodes.orangeShade,
        greenShade1: colorCodes.greenShade1,
        darkBlack2: colorCodes.darkBlack2,
      },
      chipButtonElement: {
        greyShade: colorCodes.greyShade,
        whiteShade: colorCodes.whiteShade,
        colorWhite: colorCodes.colorWhite,
      },
      infoAlertListItemComponent: {
        colorBlack: colorCodes.colorBlack,
        colorWhite: colorCodes.colorWhite,
      },
      searchBoxElement: {
        colorWhite: colorCodes.colorWhite,
      },
      selectElement: {
        colorWhite: colorCodes.colorWhite,
        colorBlack: colorCodes.colorBlack,
        blackShade: colorCodes.blackShade,
        orangeShade1: colorCodes.orangeShade1,
      },
      chartElement: {
        colorWhite: colorCodes.colorWhite,
        grayShade: colorCodes.grayShade,
        lightGrayShade: colorCodes.lightGrayShade,
        lightBlueShade: colorCodes.lightBlueShade,
        blueShade: colorCodes.blueShade,
        blueDarkShade: colorCodes.blueDarkShade,
        maroonShades: colorCodes.maroonShades,
        navyBlue: colorCodes.navyBlue,
        orangeShade: colorCodes.orangeShade,
        greenShade1: colorCodes.greenShade1,
      },
    },
    spacing: 5,
    typography: { fontFamily: "Arial" },
  },
  redTheme: {
    palette: {
      login: {
        pageBackgroundColor: "#C10132",
        loginBtnColor: "",
        titleColor: "",
        forgotPwdColor: "",
        loginBannerTitle: colorCodes.colorBlack,
        loginFormBg: colorCodes.colorWhite,
        loginBannerDescription: colorCodes.colorWhite,
        welcomeContent: colorCodes.welcomeContent,
        loginFormTitle: colorCodes.loginFormTitle,
        inputTitleColor: colorCodes.inputTitleColor,
        inputPlaceholder: colorCodes.inputPlaceholder,
        copyRight: colorCodes.darkBlack1,
        loginButton: colorCodes.blue,
        atthirate: colorCodes.lightBlue,
        logoBg: colorCodes.darkBlack,
      },
      dashboard: { pageBackgroundColor: colorCodes.redThemeColor },
      dashboardList: {
        darkGrey2: colorCodes.darkGrey2,
        lightGrey: colorCodes.lightGrey,
        darkGrey: colorCodes.darkGrey,
        darkGreen: colorCodes.darkGreen2,
        lightPurple: colorCodes.lightPurple,
        green: colorCodes.colorGreen,
        red: colorCodes.colorRed,
        orange: colorCodes.colorOrange,
        white: colorCodes.colorWhite,
        darkPurple: colorCodes.darkPurple,
        darkGrey3: colorCodes.darkGrey3,
        darkGrey4: colorCodes.darkGrey4,
        black: colorCodes.colorBlack,
        lightPurple2: colorCodes.lightPurple2,
        lightGrey1: colorCodes.lightGrey1,
        lightGrey2: colorCodes.lightGrey2,
        lightGrey3: colorCodes.lightGrey3,
        lightBlue1: colorCodes.lightBlue4,
        darkBlue2: colorCodes.darkBlue2,
        lightBlue2: colorCodes.lightBlue2,
        darkRedBlack: colorCodes.darkRedBlack,
        lightRedBlack: colorCodes.lightRedBlack,
        darkRedBlack2: colorCodes.darkRedBlack2,
        blue: colorCodes.blue,
        lightPurple1: colorCodes.lightPurple1,
        lightBlue3: colorCodes.lightBlue5,
        lightGreen: colorCodes.lightGreen,
        greenishBlue: colorCodes.greenishBlue,
        blue1: colorCodes.blue1,
        darkPink: colorCodes.darkPink,
        black1: colorCodes.black1,
        lightSkyBlue2: colorCodes.lightSkyBlue2,
        darkBlackRgba1: colorCodes.darkBlackRgba1,
      },
      profile: {
        pageBackgroundColor: "#C10132",
        lightGrey: colorCodes.lightGrey,
        darkGrey: colorCodes.darkGrey,
      },
      header: {
        darkBlueHeader: colorCodes.redThemeColor,
        colorWhite: colorCodes.colorWhite,
        avatarBgColorViolet: colorCodes.lightSkyBlue,
        darkGrayLogoutText: colorCodes.darkGray1,
        lightGrayLogoutText: colorCodes.lightGray,
        darkGreenHeaderButton: colorCodes.blackColor,
        disableGrayHeaderButtonText: colorCodes.disableGray,
        indicatorColor: colorCodes.lightBlue1,
        inactiveTabColor: colorCodes.lightBlue3,
        lightSkyBlue1: colorCodes.lightSkyBlue1,
      },
      buttonElement: {
        darkBlue1: colorCodes.darkBlue1,
        deepDarkBlue: colorCodes.deepDarkBlue,
        paleGray: colorCodes.paleGray,
        colorWhite: colorCodes.colorWhite,
      },

      infoSubListElement: {
        lightShadeGray: colorCodes.lightShadeGray,
        lightShadeGray1: colorCodes.lightShadeGray1,
        colorWhite: colorCodes.colorWhite,
      },

      infoDialogueComponent: {
        darkGrayShades: colorCodes.darkGrayShades,
        greenBlackShades: colorCodes.greenBlackShades,
        blackShades: colorCodes.blackShades,
        colorWhite: colorCodes.colorWhite,
        lightShadeGray: colorCodes.lightShadeGray,
        lightGray: colorCodes.lightGray,
        maroonShades: colorCodes.maroonShades,
        navyBlue: colorCodes.navyBlue,
        orangeShade: colorCodes.orangeShade,
        greenShade1: colorCodes.greenShade1,
        darkBlack2: colorCodes.darkBlack2,
      },
      chipButtonElement: {
        greyShade: colorCodes.greyShade,
        whiteShade: colorCodes.whiteShade,
        colorWhite: colorCodes.colorWhite,
      },
      infoAlertListItemComponent: {
        colorBlack: colorCodes.colorBlack,
        colorWhite: colorCodes.colorWhite,
      },
      searchBoxElement: {
        colorWhite: colorCodes.colorWhite,
      },
      selectElement: {
        colorWhite: colorCodes.colorWhite,
        colorBlack: colorCodes.colorBlack,
        blackShade: colorCodes.blackShade,
        orangeShade1: colorCodes.orangeShade1,
      },
      chartElement: {
        colorWhite: colorCodes.colorWhite,
        grayShade: colorCodes.grayShade,
        lightGrayShade: colorCodes.lightGrayShade,
        lightBlueShade: colorCodes.lightBlueShade,
        blueShade: colorCodes.blueShade,
        blueDarkShade: colorCodes.blueDarkShade,
        maroonShades: colorCodes.maroonShades,
        navyBlue: colorCodes.navyBlue,
        orangeShade: colorCodes.orangeShade,
        greenShade1: colorCodes.greenShade1,
      },
    },
    spacing: 5,
    typography: { fontFamily: "Arial" },
  },
};

export default theme;
