import { makeStyles } from "@mui/styles";
import muiTheme from "theme/muiTheme";

const useStyles = makeStyles({
  alertsContainerMain: (props: any) => ({}),
  infoIconContainer: (props: any) => ({
    position: "absolute",
    bottom: 0,
    background: props?.palette?.dashboardList?.darkBlackRgba1,
    margin: "10px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    width: "calc(100% - 20px)",
    backdropFilter: "blur(7.5px)",
    [muiTheme.breakpoints.down(801)]: {
      overflow: " auto",
    },
  }),
  floorMapContainerStyle: (props: any) => ({
    width: "100%",
    height: "calc(100vh - 114px)",
    position: "relative",
    [muiTheme.breakpoints.down(1335)]: {
      height: "calc(100vh - 79px)",
    },
    "& .react-transform-wrapper": {
      height: "calc(100vh - 200px)",
    },
  }),
  fullscreenMapStyle: (props: any) => ({
    width: "100vw",
    height: "100vh",
    position: "absolute",
    top: 0,
    left: 0,
    background: "rgba(0, 0, 0, 0.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 50000,
  }),
  fullscreenMapInnerStyle: (props: any) => ({
    width: "80vw",
    height: "90vh",
    backgroundColor: "#FFF",
  }),
});
export default useStyles;
