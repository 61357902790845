let colorCodes = {
  darkBlueThemeColor: "#300254",
  greenThemeColor: "#68F73A",
  yellowThemeColor: "#D1F557",
  redThemeColor: "#C10132",
  blackColor: "#4B0000",
  blueDarkColor: "#34BACF",
  colorWhite: "#ffffff",
  colorBlack: "#000000",
  loginFormBg: "#EFF4FA",
  welcomeContent: "#3A345E",
  loginFormTitle: "#1A202C",
  inputTitleColor: "#4A5568",
  inputPlaceholder: "#2D3748",
  colorDark: "#39335D",
  lightGrey: "#d8d8d8",
  darkGrey: "#2D2946",
  darkBlue: "#2D2946",
  violet: "#9486DA",
  darkGray1: "#716F6F",
  lightGray: "#d7d7d7",
  darkGreen: "#4FB157",
  darkGreen2: "#66996A",
  lightPurple: "#E7DFFF",
  lightPurple2: "#EEE9FF",
  darkPurple: "#9087C8",
  disableGray: "#A4A4AD",
  darkBlue1: "#2A859B",
  deepDarkBlue: "#123D64",
  paleGray: "#A0B1C1",
  colorGreen: "#26C96B",
  colorRed: "#C24646",
  colorOrange: "#F1933B",
  darkGrey2: "#837DAB",
  darkGrey3: "#565656",
  darkGrey4: "#6B6B6B",
  //QualComm Color
  blue: "#3253DC",
  lightBlue: "#0C1C5E",
  darkBlack: "#212431",
  lightSkyBlue: "#4F69F1",
  darkQualcomBlue: "#212431",
  lightBlue1: "#68B5C7",
  darkBlack1: "#4D4D4D",
  darkBlack2: "#060606",
  lightShadeGray: "#dddddd",
  lightShadeGray1: "#c6c2c2",
  purpleShades: "#7E6ECC",
  darkGrayShades: "#646464",
  greenBlackShades: "#77B77C",
  blackShades: "#111111",
  greyShade: "#727272",
  whiteShade: "#ededed",
  greenShade: "#5AA700",

  lightGrey1: "#F2F5F9",
  lightGrey2: "#E1E5F8",
  lightBlue4: "#8693A8",
  darkBlue2: "#132740",
  lightGrey3: "#A8A8A8",
  lightBlue2: "#97AAED",
  darkRedBlack: "#323232",
  lightRedBlack: "#828282",
  darkRedBlack2: "#424141",

  lightPurple1: "#9E9EFF",
  lightBlue5: "#47B3CC",
  lightGreen: "#72B484",
  greenishBlue: "#1CAB91",
  blue1: "#6580F2",
  darkPink: "#F18080",
  black1: "#151515",
  lightBlue3: "#939DC6",
  lightSkyBlue1: "#939DC6",

  maroonShades: "#D94C7D",
  navyBlue: "#2A66C5",
  orangeShade: "#F19D38",
  greenShade1: "#549D55",

  blackShade: "#2B2B2B",
  orangeShade1: "#FF5910",

  grayShade: "#CCCCCC",
  lightGrayShade: "#C4C4C4",
  lightBlueShade: "#949FFF",
  blueShade: "#456EFF",
  blueDarkShade: "#0C8ACC",
  lightSkyBlue2: "#B7B7DA",
  darkBlackRgba1: "rgba(21, 21, 21, 0.9)",
  lightWhiteRgba2: "rgba(0, 0, 0, 0.1)",
  darkBlackRgba2: "rgba(6, 6, 6, 0.9)",
};
export default colorCodes;
