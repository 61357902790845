import { useState, useEffect, Fragment } from "react";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import theme from "../../theme/theme";
import FloorMap from "../FloorMap";
import DashboardMap from "components/DashboardMap";
import DashboardList from "../DashboardList";
import dashBoardEquipments from "../../mockdata/dashBoardEquipments";
import useStyles from "./styles";
import { fullscreen } from "assets/floormap";
import InfoDialog from "components/InfoDialog";

const DashboardContainer: React.FC<any> = (props) => {
  const {} = props;

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const { fullscreenMapStyle, fullscreenMapInnerStyle } = useStyles(appTheme);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );
  const [selectedNotification, setSelectedNotification] = useState<any>(-1);
  const [showFullscreenMap, setShowFullscreenMap] = useState(false);
  const [viewDialogueIsOpen, setViewDialogueIsOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>();

  const handleViewDialogue = (id: number, event: any) => {
    setShowFullscreenMap(false);
    setViewDialogueIsOpen(!viewDialogueIsOpen);
    setSelectedNotification(-1)
    setSelectedId(id);
  };

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const dashboardListData: any = dashBoardEquipments?.equipments;

  const [dashboardEquipmentData, setDashboardEquipmentData] =
    useState(dashboardListData);

  useEffect(() => {
    const dataValue = dashboardEquipmentData?.map(
      (value: any, index: number) => {
        return { ...value, indexVal: index };
      }
    );
    setDashboardEquipmentData(dataValue);
  }, []);

  useEffect(() => {}, [dashboardEquipmentData]);

  let currentTimeStampValue;
  let timeArrayNew: any = [];
  for (let i = 0; i < dashboardListData?.length; i++) {
    currentTimeStampValue = moment()
      .subtract({
        hours: i === 0 ? i : i > 20 ? 20 : i + 1,
        minutes: i + 59,
        seconds: i + 49,
      })
      .format("MM-DD-YYYY | h:mm A");
    timeArrayNew.push({ indexVal: i, currentTimeStamp: currentTimeStampValue });
  }

  let combinedData = timeArrayNew?.map((item: any, i: any) =>
    Object.assign({}, item, dashboardEquipmentData[i])
  );

  const [notificationTimeStamp, setNotificationTimeStamp] = useState();

  useEffect(() => {
    if (selectedNotification || selectedNotification) {
      for (let i = 0; i <= combinedData?.length; i++) {
        if (
          combinedData[i]?.id === (selectedNotification || selectedNotification)
        ) {
          setNotificationTimeStamp(combinedData[i]?.currentTimeStamp);
        }
      }
    }
  }, [selectedNotification, selectedNotification, notificationTimeStamp]);

  return (
    <Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={9.2}>
            <DashboardMap
              dashboardData={combinedData}
              dashboardMainList={dashBoardEquipments}
              selectedNotification={selectedNotification}
              setSelectedMarker={setSelectedNotification}
              selectedMarker={selectedNotification}
              setShowFullscreenMap={setShowFullscreenMap}
              showFullscreenMap={showFullscreenMap}
              notificationTimeStamp={notificationTimeStamp}
              handleViewDialogue={handleViewDialogue}
              viewDialogueIsOpen={viewDialogueIsOpen}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={2.8}>
            <DashboardList
              dashboardData={combinedData}
              selectedNotification={selectedNotification}
              setSelectedNotification={setSelectedNotification}
              notificationTimeStamp={notificationTimeStamp}
              selectedMarker={selectedNotification}
              handleViewDialogue={handleViewDialogue}
            />
          </Grid>
        </Grid>
      </Box>
      {showFullscreenMap && (
        <div className={fullscreenMapStyle}>
          <div className={fullscreenMapInnerStyle}>
            <DashboardMap
              dashboardData={combinedData}
              dashboardMainList={dashBoardEquipments}
              setSelectedMarker={setSelectedNotification}
              selectedNotification={selectedNotification}
              selectedMarker={selectedNotification}
              setShowFullscreenMap={setShowFullscreenMap}
              showFullscreenMap={showFullscreenMap}
              handleViewDialogue={handleViewDialogue}
              viewDialogueIsOpen={viewDialogueIsOpen}
            />
          </div>
        </div>
      )}
      {viewDialogueIsOpen && (
        <InfoDialog
          selectedId={selectedId}
          setViewDialogueIsOpen={setViewDialogueIsOpen}
        />
      )}
    </Fragment>
  );
};
export default DashboardContainer;
