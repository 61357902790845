import { makeStyles } from "@mui/styles";
import muiTheme from "../../theme/muiTheme";

const useStyles = makeStyles(
  {
    infoIconMainClass: (props: any) => ({
      margin: 5,
      flex: 1,
      [muiTheme.breakpoints.down(1547)]: {
        margin: 6,
      },
    }),
    infoBoxClass: (props: any) => ({
      display: "inline-flex",
      "& .MuiFab-root": {
        [muiTheme.breakpoints.down(1547)]: {
          width: "36px !important",
          height: "30px !important",
          margin: 0,
          marginRight: 6,
        },
      },
    }),
    infoTextContent: (props: any) => ({
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }),
    infoTextH1: (props: any) => ({
      margin: 0,
      padding: 0,
      color: props?.palette?.dashboardList?.white,
      fontSize: 21,
      lineHeight: "40.85px",
      fontFamily: `"QualcommNext-Regular"`,
      [muiTheme.breakpoints.down(1547)]: {
        fontSize: 12,
        lineHeight: "20px !important",
      },
    }),
    infoTextH4: (props: any) => ({
      margin: 0,
      padding: 0,
      color: props?.palette?.dashboardList?.lightSkyBlue2,
      fontSize: 14,
      lineHeight: "19.07px",
      fontFamily: `"QualcommNext-Thin"`,
      [muiTheme.breakpoints.down(1547)]: {
        fontSize: 8,
        lineHeight: "14px !important",
      },
    }),
    imageWidth: (props: any) => ({
      [muiTheme.breakpoints.down(1547)]: {
        width: "26px !important",
        height: "16px !important",
      },
      [muiTheme.breakpoints.down(1025)]: {
        width: "26px !important",
        height: "16px !important",
      },
    }),
  },
  { index: 1 }
);
export default useStyles;
