export default {
  title: "Hello Infinite Italian",
  profile: "Profile Italian",
  hello: "Hola",
  changeLang:"Change Language",
  changeTheme:"Change Theme",
 
  infoDialogueNotifications: "Avvisi",

  infoSubListTemprature: "Temperatura",
  infoSubListHumidity: "Umidità",
  infoSubListOprHrs: "Opr.Hrs",
  infoSubListPowerUsage: "Il consumo di energia",
  infoSubListVibration: "Vibrazione",
  infoSubListPressure: "Pressione",
  infoSubListPredictiveMaintenance: "Manutenzione predittiva",
  infoSubListSafetyInfractions: "Infrazioni di sicurezza",
  infoSubListBatteryLevel: "Livello della batteria",
  infoSubListTripsRemaining: "Viaggi rimanenti",

  chipButtonEvents: "Eventi",
  chipButtonIncidents: "Incidenti",
  chipButtonOprAlerts: "Opr.Avvisi",
  infoTabsListDetails: "DETTAGLI",
  infoTabsListGeofence: "GEOFENCE",
  infoGraphTitleHoursOfOperation: "Ore di operazione",
  infoGraphTitleTrips: "Viaggi",
 
  dashboardListName: "Attrezzatura",
  search: "Ricerca",
  noResult: "Nessun risultato trovato",
  itemIconList1: "Temperatura",
  itemIconList2: "Umidità",
  itemIconList3: "Opr. Ore",
  itemIconList4: "Consumo Energia",
  itemListButton: "Vedi Dettagli",
  dashboardTab: "Pannello di controllo",
  alertsTab: "Avvisi",
  analyticsTab: "Analitica",
  configurationTab: "Configurazione",
  logout: "Disconnettersi",
  infoIconTitle1: "Numero Di Pallet",
  infoIconTitle2: "Energy Consumed",
  infoIconTitle3: "Backup Power",
  infoIconTitle4: "Production Efficiency",
  infoIconTitle5: "Hours of Operation",
  infoIconTitle6: "Workers",
  infoIconTitle7: "Safety Infractions",
  poweredBy: "Offerto da",
  copyRight: "© 2023. Tutti i diritti riservati"
};
