import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  customCheckbox: {
    // "&.Mui-checked": {
    //   color: `#ffffff !important`,
    // },
  },
}));

export default useStyles;
