const alertsListData = {
  infoList: [
    {
      title: "Safety Infraction",
      value: "54",
    },
    {
      title: "Downtime",
      value: "2hrs",
    },

    {
      title: "Backup Power",
      value: "100%",
    },
    {
      title: "Days since last maintenance",
      value: "Jan 20, 2023",
    },
    {
      title: "Next Maintenance",
      value: "Feb 15, 2023",
    },
  ],
  alertsList: {
    events: [
      {
        category: "conveyerBelt",
        id: "12",
        area: "Production Area, Zone 2",
        name: "Conveyor Belt#03",
        title: "Maintanence In Progress",
        subTitle: "",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 490,
          y: 655,
        },
      },

      {
        category: "workers",
        id: "72",
        area: "Inspection Area, Zone 2",
        name: "Worker #16",
        title: "Work Completed",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 110,
          y: 270,
        },
      },

      {
        category: "workers",
        id: "72",
        area: "Inspection Area, Zone 2",
        name: "Worker #14",
        title: "Work Allocated",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 335,
          y: 450,
        },
      },

      {
        category: "robotArm",
        id: "11",
        area: "Packaging Area, Zone 1",
        name: "Robotic Arm Packager #13",
        title: "Maintanence In Progress",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 230,
          y: 240,
        },
      },

      {
        category: "forklift",
        inCharging: false,
        id: "3",
        area: "Distribution Area, Zone 1",
        name: "Autonomous Forklift#1",
        title: "Maintanence Completed",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 1100,
          y: 200,
        },
      },

      {
        category: "forklift",
        inCharging: false,
        id: "3",
        area: "Distribution Area, Zone 1",
        name: "Autonomous Forklift#2",
        title: "Maintanence In Progress",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 1060,
          y: 140,
        },
      },

      {
        category: "aiCamera",
        id: "4",
        area: "Inspection Area, Zone 2",
        name: "AI Camera #24",
        title: "Maintenance completed",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 50,
          y: 450,
        },
      },

      {
        category: "amr",
        inCharging: false,
        id: "15",
        area: "Packaging Area, Zone 1",
        name: "AMR#23",
        title: "Charging Completed",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 550,
          y: 160,
        },
      },

      {
        category: "workers",
        id: "84",
        area: "Packaging Area, Zone 1",
        name: "Worker#2",
        title: "Work Inprogress",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 696,
          y: 360,
        },
      },

      {
        category: "workers",
        id: "83",
        area: "Packing Area, Zone 1",
        name: "Worker#1",
        title: "Work Completed",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 300,
          y: 185,
        },
      },
      {
        category: "workers",
        id: "84",
        area: "Packaging Area, Zone 1",
        name: "Worker#3",
        title: "Work In Progress",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 590,
          y: 230,
        },
      },
      {
        category: "workers",
        id: "85",
        area: "Inspection Area, Zone 2",
        name: "Worker#3",
        title: "Job Allocated",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 80,
          y: 600,
        },
      },
      {
        category: "workers",
        id: "86",
        area: "Inspection Area, Zone 2",
        name: "Worker#4",
        title: "Work Completed",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 160,
          y: 690,
        },
      },

      {
        category: "workers",
        id: "87",
        area: "Inspection Area, Zone 2",
        name: "Worker#5",
        title: "Job Allocated",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 260,
          y: 705,
        },
      },
    ],
    alerts: [
      {
        category: "forklift",
        inCharging: false,
        id: "2",
        area: "Distribution Area, Zone 1",
        name: "Autonomous Forklift#5",
        title: "High Power Usage",
        subTitle: "Threshold limit - 65KW",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 1010,
          y: 200,
        },
      },

      {
        category: "workers",
        id: "83",
        area: "Production Area, Zone 2",
        name: "Worker#1",
        title: "Safety Infraction",
        subTitle: "",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 1080,
          y: 760,
        },
      },

      {
        category: "conveyerBelt",
        id: "12",
        area: "Production Area, Zone 2",
        name: "Conveyor Belt#02",
        title: "High Belt Speed",
        subTitle: "Threshold limit - 68RPM",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 260,
          y: 560,
        },
      },

      {
        category: "amr",
        inCharging: false,
        id: "17",
        area: "Inspection Area, Zone 2",
        name: "AMR#25",
        title: "High Temperature",
        subTitle: "Threshold limit - 70°C",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 5,
          y: 360,
        },
      },
      {
        category: "forklift",
        inCharging: true,
        id: "1",
        area: "Distribution Area, Zone 1",
        name: "Autonomous Forklift#4",
        title: "In Charging",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 1300,
          y: 130,
        },
      },

      {
        category: "aiCamera",
        id: "4",
        area: "Packing Area, Zone 1",
        name: "AI Camera #23",
        title: "Camera Occlusion",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 462,
          y: 190,
        },
      },

      {
        category: "packager",
        id: "19",
        area: "Packaging Area, Zone 1",
        name: "Packager05",
        title: "High Temperature",
        subTitle: "Threshold limit - 80°C",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 515,
          y: 255,
        },
      },

      {
        category: "amr",
        inCharging: false,
        id: "21",
        area: "Distribution Area, Zone 1",
        name: "AMR#23",
        title: "Maintenance Completed",
        subTitle: "",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 1250,
          y: 165,
        },
      },
      {
        category: "equipment-3",
        id: "22",
        area: "Production Area, Zone 2",
        name: "Equipment#3",
        title: "High Vibration",
        subTitle: "Threshold limit - 65mm",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 1240,
          y: 700,
        },
      },

      {
        category: "workers",
        id: "89",
        area: "Production Area, Zone 2",
        name: "Worker#7",
        title: "Low Safety Score",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 690,
          y: 670,
        },
      },

      {
        category: "robotArm",
        id: "7",
        area: "Palleting Area, Zone 1",
        name: "Robotic Arm Packager #15",
        title: "High Maintenance Hours",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 775,
          y: 220,
        },
      },
    ],
    operations: [
      {
        category: "forklift",
        inCharging: false,
        id: "1",
        area: "Distribution Area, Zone 1",
        name: "Autonomous Forklift#1",
        title: "Low Operating Level",
        subTitle: "",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 1190,
          y: 193,
        },
      },
      {
        category: "robotArm",
        id: "6",
        area: "Packaging Area, Zone 1",
        name: "Robotic Arm Packager #14",
        title: " Maintenance Due",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 340,
          y: 255,
        },
      },

      // {
      //   category: "worker",
      //   id: "62",
      //   area: "Producation Area, Zone 2",
      //   name: "Worker#11",
      //   title: "Work Delayed",
      //   subTitle: " ",
      //   timeStamp: "12-15-2022 | 09:00 AM",
      //   location: {
      //     x: 1060,
      //     y: 560,
      //   },
      // },
      // {
      //   category: "worker",
      //   id: "63",
      //   area: "Production Area, Zone 2",
      //   name: "worker#12",
      //   title: "Low Operating Hours",
      //   subTitle: " ",
      //   timeStamp: "12-15-2022 | 09:00 AM",
      //   location: {
      //     x: 1190,
      //     y: 790,
      //   },
      // },

      {
        category: "workers",
        id: "89",
        area: "Production Area, Zone 2",
        name: "Worker#7",
        title: "Low Safety Score",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 1060,
          y: 560,
        },
      },

      {
        category: "amr",
        inCharging: false,
        id: "21",
        area: "Distribution Area, Zone 1",
        name: "AMR#24",
        title: "Low Equipment Efficiency",
        subTitle: "",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 1300,
          y: 220,
        },
      },

      {
        category: "pallet",
        id: "12",
        area: "Palleting Area, Zone 1",
        name: "Pallet#2",
        title: "Maintenance Due",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 930,
          y: 220,
        },
      },
      {
        category: "workers",
        id: "88",
        area: "Production Area, Zone 2",
        name: "Worker#6",
        title: "Low Productivity Score",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 620,
          y: 730,
        },
      },

      {
        category: "workers",
        id: "90",
        area: "Production Area, Zone 2",
        name: "Worker#8",
        title: "Work Delayed",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 730,
          y: 740,
        },
      },
      {
        category: "workers",
        id: "90",
        area: "Production Area, Zone 2",
        name: "Worker#8",
        title: "Low Operating Hours",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 1190,
          y: 760,
        },
      },
      {
        category: "equipment",
        id: "20",
        area: "Production Area, Zone 2",
        name: "Equipment#1",
        title: "High Maintanence Hours",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 960,
          y: 700,
        },
      },
      {
        category: "equipment-2",
        id: "20",
        area: "Production Area, Zone 2",
        name: "Equipment#2",
        title: "Low Operating Hours",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 1100,
          y: 700,
        },
      },
      {
        category: "workers",
        id: "71",
        area: "Production Area, Zone 2",
        name: "Worker#9",
        title: "Low Productivity Score",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 1340,
          y: 540,
        },
      },
      {
        category: "workers",
        id: "72",
        area: "Producation Area, Zone2",
        name: "worker#10",
        title: "Work Delayed",
        subTitle: " ",
        timeStamp: "12-15-2022 | 09:00 AM",
        location: {
          x: 1100,
          y: 520,
        },
      },
    ],
  },
};

export default alertsListData;
