import { makeStyles } from "@mui/styles";
import muiTheme from "../../theme/muiTheme";

const useStyles = makeStyles({
  rootContainer: (props: any) => ({
    background: "#F2F5F9",
    height: "calc(100vh - 124px)",
    padding: "25px 15px 15px 15px",
    fontFamily: `"QualcommNext-Regular"`,
    [muiTheme.breakpoints.down(1335)]: {
      height: "calc(100vh - 119px)",
    },
  }),
  headingContainer: (props: any) => ({
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid #8693A8`,
    alignItems: "center",
    paddingBottom: "5px",
  }),
  title: (props: any) => ({
    fontSize: 20,
    color: "#132740",
    width: "85%",
    fontWeight: 600,
    fontFamily: `"QualcommNext-Regular"`,
    lineHeight: "24px",
    textTransform: "capitalize",
    [muiTheme.breakpoints.down(1281)]: {
      fontSize: 18,
    },
  }),
  searchImage: (props: any) => ({
    cursor: "pointer",
  }),
  listSection: (props: any) => ({
    height: "calc(100vh - 216px)",
    overflow: "auto",
    paddingTop: "20px",
    [muiTheme.breakpoints.down(1335)]: {
      height: "calc(100vh - 175px)",
    },
  }),
  searchClass: (props: any) => ({
    border: `1px solid ${props?.palette?.dashboardList?.lightGrey}`,
    background: props?.palette?.dashboardList?.white,
    color: props?.palette?.dashboardList?.lightGrey3,
    borderRadius: 6,
    height: "40px",
    "& .MuiIconButton-root": {
      marginRight: 7,
    },
    "& .MuiInputBase-root": {
      fontWeight: 500,
    },
  }),
});
export default useStyles;
