import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import useTranslation from "../../localization/translations";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InfoSubList from "../InfoSubList";
import equipmentViewDetails from "../../mockdata/equipmentViewDetails";
// import CustomizedSteppers from "../Stepper";

import ChipButton from "../../elements/ChipButton";
import Tabs from "../../elements/Tabs";
import { SearchIconInfo } from "../../assets/buttonIcons";
import theme from "../../theme/theme";
import colorCodes from "../../theme/colors";
import useStyles from "./styles";
import Select from "../../elements/Select";
import Chart from "../../elements/Chart";
import InfoAlertListItem from "../InfoAlertListItem";
import SearchBox from "../../elements/SearchBox";
import { CloseIconWhite } from "../../assets/buttonIcons";
import moment from "moment";
import useWindowDimensions from "hooks/useWindowDimensions";
import RealTimeChart from "elements/RealTimeChart";
import muiTheme from "theme/muiTheme";

const DialogWrapper = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiBackdrop-root": {
    display: "none",
    // marginTop: "60px !important",
  },
  "& .MuiPaper-root": {
    maxHeight: "calc(100% - 110px)",
    width: "100vw",
    maxWidth: "1722px",
    background: `${colorCodes.darkBlackRgba2} !important`,
    color: colorCodes.colorWhite,
    padding: 24,
    borderRadius: "15px",
  },
  "& .MuiDialog-container": {
    marginTop: "84px !important",
    background: `${colorCodes.lightWhiteRgba2} !important`,
    backdropFilter: "blur(8px)",
    height: "calc(100vh - 84px) !important",
    [muiTheme.breakpoints.down(1335)]: {
      marginTop: "50px !important",
      height: "calc(100vh - 50px) !important",
    },
  },
}));

const InfoDialog: React.FC<any> = (props) => {
  const { selectedId, setViewDialogueIsOpen } = props;

  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const {
    headerStyle,
    tripsSection1,
    tripsSection,
    violationSection,
    tripsSection2,
    subListRow,
    subListSection,
    chipButtonSection,
    customNotificationTabs,
    incomeCurrentSection,
    incomeText,
    searchClass,
    notificationHeading,
    notificationSearchHeading,
    notificationSearchIcon,
    notificationTabHeading,
    notificationList,
    customNotificationChipTabs,
  } = useStyles(appTheme);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  const {
    infoDialogueNotifications,
    chipButtonEvents,
    chipButtonIncidents,
    chipButtonOprAlerts,
  } = useTranslation();

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const [equipmentViewDetailsItem, setEquipmentViewDetailsItem] = useState<any>(
    equipmentViewDetails?.map((item: any) => {
      if (item?.id === selectedId) {
        return item;
      }
    })
  );

  const { width, height } = useWindowDimensions();
  const [chartWidth, setChartWidth] = useState<number>(500);
  const [chartHeight, setChartHeight] = useState<number>(187);

  useEffect(() => {
    if (width <= 1024) {
      setChartWidth(410);
      setChartHeight(141);
    } else if (height <= 600) {
      setChartWidth(410);
      setChartHeight(68);
    } else if (height <= 633) {
      setChartWidth(410);
      setChartHeight(60);
    } else if (height <= 720) {
      setChartWidth(410);
      setChartHeight(118);
    } else if (height <= 768) {
      setChartWidth(410);
      setChartHeight(110);
    } else if (height <= 800) {
      setChartWidth(410);
      setChartHeight(150);
    } else if (height <= 820) {
      setChartWidth(410);
      setChartHeight(160);
    } else if (height <= 824) {
      setChartWidth(410);
      setChartHeight(140);
    } else if (height <= 900) {
      setChartWidth(410);
      setChartHeight(160);
    } else if (height <= 937) {
      setChartWidth(410);
      setChartHeight(176);
    } else if (height <= 960) {
      setChartWidth(410);
      setChartHeight(185);
    } else if (height <= 1024) {
      setChartWidth(410);
      setChartHeight(183);
    } else if (height <= 1050) {
      setChartWidth(410);
      setChartHeight(170);
    } else if (height <= 1120) {
      setChartWidth(410);
      setChartHeight(183);
    } else if (width <= 1152) {
      setChartWidth(410);
      setChartHeight(142);
    } else if (width <= 1280) {
      setChartWidth(410);
      setChartHeight(210);
    } else if (width <= 1280) {
      setChartWidth(410);
      setChartHeight(210);
    } else if (width <= 1280) {
      setChartWidth(410);
      setChartHeight(210);
    } else if (width <= 1366) {
      setChartWidth(410);
      setChartHeight(94);
    } else if (width <= 1600) {
      setChartWidth(410);
      setChartHeight(158);
    } else if (width <= 1680) {
      setChartWidth(410);
      setChartHeight(170);
    } else if (width <= 2000) {
      setChartWidth(410);
      setChartHeight(146);
    } else if (width <= 2732) {
      setChartWidth(410);
      setChartHeight(187);
    }
  }, [width, height]);

  useEffect(() => {
    equipmentViewDetails?.map((item: any) => {
      if (item?.id === selectedId) {
        setEquipmentViewDetailsItem(item);
      }
    });
  }, []);

  const [open, setOpen] = useState(!false);

  const handleClose = () => {
    setOpen(!open);
    setViewDialogueIsOpen(false);
  };

  const [infoSubList, setInfoSubList] = useState<any>(
    equipmentViewDetailsItem?.infoData
  );

  const tabsList = [
    {
      name:
        equipmentViewDetailsItem?.title && equipmentViewDetailsItem?.area
          ? `${equipmentViewDetailsItem?.title} - ${equipmentViewDetailsItem?.area}`
          : "",
      val: 0,
    },
  ];

  // const notifcationChipButtons = [
  //   {
  //     label: "Events",
  //     count: equipmentViewDetailsItem?.alerts?.events?.length,
  //     focused: false,
  //     color: "green",
  //   },
  //   {
  //     label: "Alerts",
  //     count: equipmentViewDetailsItem?.alerts?.incidents?.length,
  //     focused: true,
  //     color: "red",
  //   },
  //   {
  //     label: "Operations",
  //     count: equipmentViewDetailsItem?.alerts?.oprAlerts?.length,
  //     focused: false,
  //     color: "orange",
  //   },
  // ];

  const chipTabsList = [
    {
      name: "Events",
      val: 0,
      count: equipmentViewDetailsItem?.alerts?.events?.length,
    },
    {
      name: "Alerts",
      val: 1,
      count: equipmentViewDetailsItem?.alerts?.incidents?.length,
    },
    {
      name: "Operations",
      val: 2,
      count: equipmentViewDetailsItem?.alerts?.oprAlerts?.length,
    },
  ];

  // const getFormattedChipButtonsData = (list: any, chipBtnIndex: number) => {
  //   for (const [index] of list.entries()) {
  //     if (chipBtnIndex === index) list[index].focused = true;
  //     else list[index].focused = false;
  //   }

  //   return list;
  // };

  const [newSearchValue, setNewSearchValue] = useState<any>(
    equipmentViewDetailsItem?.alerts?.incidents
  );

  // const [chipButtons, setChipButtons] = useState<any>(notifcationChipButtons);
  const [alertDataList, setAlertDataList] = useState<any>(
    equipmentViewDetailsItem?.alerts?.incidents
  );

  // const handleChipButton = (data: any, index: number) => {
  //   setChipButtons([
  //     ...chipButtons,
  //     getFormattedChipButtonsData(chipButtons, index),
  //   ]);
  //   switch (data.label) {
  //     case "Events":
  //       setAlertDataList(equipmentViewDetailsItem?.alerts?.events);
  //       setNewSearchValue(equipmentViewDetailsItem?.alerts?.events);
  //       break;
  //     case "Incidents":
  //       setAlertDataList(equipmentViewDetailsItem?.alerts?.incidents);
  //       setNewSearchValue(equipmentViewDetailsItem?.alerts?.incidents);
  //       break;
  //     case "Opr.Alerts":
  //       setAlertDataList(equipmentViewDetailsItem?.alerts?.oprAlerts);
  //       setNewSearchValue(equipmentViewDetailsItem?.alerts?.oprAlerts);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const [chipTabIndex, setchipTabIndex] = useState<number>(1);

  const handleChipTabs = (index: number) => {
    setchipTabIndex(index);
    setSearchIsOpen(false);
    switch (index) {
      case 0:
        setAlertDataList(equipmentViewDetailsItem?.alerts?.events);
        setNewSearchValue(equipmentViewDetailsItem?.alerts?.events);
        break;
      case 1:
        setAlertDataList(equipmentViewDetailsItem?.alerts?.incidents);
        setNewSearchValue(equipmentViewDetailsItem?.alerts?.incidents);
        break;
      case 2:
        setAlertDataList(equipmentViewDetailsItem?.alerts?.oprAlerts);
        setNewSearchValue(equipmentViewDetailsItem?.alerts?.oprAlerts);
        break;
      default:
        break;
    }
  };

  const [tabIndex, setTabIndex] = useState<number>(0);
  const handleTabs = (index: number) => {
    setTabIndex(index);
  };

  const [tempratureGraphData, setTempratureGraphData] = useState<any>();
  const [humidityGraphData, setHumidityGraphData] = useState<any>();
  const [hoursOfOperationGraphData, setHoursOfOperationGraphData] =
    useState<any>();
  const [tripsGraphData, setTripsGraphData] = useState<any>();

  const [tempratureGraphDataStateUpdates, setTempratureGraphDataStateUpdates] =
    useState<any>();
  const [humidityGraphDataStateUpdates, setHumidityGraphDataStateUpdates] =
    useState<any>();
  const [
    hoursOfOperationGraphDataStateUpdates,
    setHoursOfOperationGraphDataStateUpdates,
  ] = useState<any>();
  const [tripsGraphDataStateUpdates, setTripsGraphDataStateUpdates] =
    useState<any>();

  const [graphOneTitle, setGraphOneTitle] = useState<string>();
  const [graphTwoTitle, setGraphTwoTitle] = useState<string>();
  const [graphThreeTitle, setGraphThreeTitle] = useState<string>();
  const [graphFourTitle, setGraphFourTitle] = useState<string>();

  useEffect(() => {
    setNewSearchValue(equipmentViewDetailsItem?.alerts?.incidents);
    setInfoSubList(equipmentViewDetailsItem?.infoData);
    setAlertDataList(equipmentViewDetailsItem?.alerts?.incidents);
    // setChipButtons(notifcationChipButtons);
    equipmentViewDetailsItem?.analytics?.map((data: any, index: number) => {
      switch (index) {
        case 0:
          setTempratureGraphDataStateUpdates(data?.data?.weekly?.analyticsData);
          setTempratureGraphData(data?.data);
          setGraphOneTitle(data?.type);

          break;
        case 1:
          setHumidityGraphDataStateUpdates(data?.data?.weekly?.analyticsData);
          setHumidityGraphData(data?.data);
          setGraphTwoTitle(data?.type);
          break;
        case 2:
          setHoursOfOperationGraphDataStateUpdates(
            data?.data?.weekly?.analyticsData
          );
          setHoursOfOperationGraphData(data?.data);
          setGraphThreeTitle(data?.type);
          break;
        case 3:
          setTripsGraphDataStateUpdates(data?.data?.weekly?.analyticsData);
          setTripsGraphData(data?.data);
          setGraphFourTitle(data?.type);

          break;
        default:
        // setTempratureGraphDataStateUpdates(data?.data?.day?.analyticsData);
        // setTempratureGraphData(data?.data);
      }
    });
  }, [equipmentViewDetailsItem]);

  useEffect(() => {
    getTempratureGraphData();
    getHumidityGraphData();
    getHoursOfOperationGraphData();
    getTripsGraphData();
  }, [
    tempratureGraphDataStateUpdates,
    humidityGraphDataStateUpdates,
    hoursOfOperationGraphDataStateUpdates,
    tripsGraphDataStateUpdates,
  ]);

  const [updatedTempratureGraphData, setUpdatedTempratureGraphData] =
    useState<any>();
  const [updatedHumidityGraphData, setUpdatedHumidityGraphData] =
    useState<any>();
  const [
    updatedHoursOfOperationGraphData,
    setUpdatedHoursOfOperationGraphData,
  ] = useState<any>();
  const [updatedTripsGraphData, setUpdatedTripsGraphData] = useState<any>();

  const getTempratureGraphData = () => {
    let data = [
      {
        data: graphDataManipulation(tempratureGraphDataStateUpdates),

        color:
          graphOneTitle === "Operating Hours"
            ? "#7979D7"
            : appTheme.palette.infoDialogueComponent.maroonShades,
      },
    ];

    setUpdatedTempratureGraphData(data);
  };

  const getHumidityGraphData = () => {
    const data = [
      {
        data: graphDataManipulation(humidityGraphDataStateUpdates),

        color: appTheme.palette.infoDialogueComponent.navyBlue,
      },
    ];
    setUpdatedHumidityGraphData(data);
  };

  const getHoursOfOperationGraphData = () => {
    const data = [
      {
        data: graphDataManipulation(hoursOfOperationGraphDataStateUpdates),

        color: "#7979D7",
      },
    ];
    setUpdatedHoursOfOperationGraphData(data);
  };

  const getTripsGraphData = () => {
    let data = [
      {
        data: graphDataManipulation(tripsGraphDataStateUpdates),

        color: "#65D9F2",
      },
    ];

    setUpdatedTripsGraphData(data);
  };

  const graphDataManipulation = (analyticsGraphData: any) => {
    let manipulatedGraphData = [];
    if (analyticsGraphData) {
      if (analyticsGraphData) {
        for (let i = 0; i < analyticsGraphData?.length; i++) {
          manipulatedGraphData.push([
            // new Date(analyticsGraphData[i]?.node).getTime(),
            analyticsGraphData[i]?.count,
          ]);
        }
      }
    }
    return manipulatedGraphData;
  };

  // Updated Time 12Hrs Format - Day

  let times: any = [],
    periods: any = ["AM", "PM"],
    hours: any = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    prop: any = null,
    hour: any = null,
    min: any = null;

  for (prop in periods) {
    for (hour in hours) {
      for (min = 0; min < 60; min += 60) {
        times.push(
          ("0" + hours[hour]).slice(-2) +
            ":" +
            ("0" + min).slice(-2) +
            " " +
            periods[prop]
        );
      }
    }
  }

  const currentTime = moment().format("h A");
  const xAxisArray = times.filter(
    (value: any) =>
      moment(value, ["h A"]).format("HH") <
      moment(currentTime, ["h A"]).format("HH")
  );
  const xAxisTimeArray = xAxisArray.slice(
    xAxisArray.length - 10,
    xAxisArray.length
  );
  const xAxisNewValueTime = Array.from(xAxisTimeArray, (ps) => ps);

  // Updated Time 24Hrs Format - Day

  var hoursPerDay: any = 24;
  var xAxisNewtime: any = [];

  function timeOneDay() {
    var formattedTime;
    var newTimeFormat;
    for (let i = 0; i < hoursPerDay + 1; i++) {
      formattedTime = moment().subtract(i, "hours").format("HH");
      newTimeFormat = formattedTime + ":00";
      xAxisNewtime.unshift(newTimeFormat);
    }
    const newTimePop = xAxisNewtime.pop();
  }
  timeOneDay();

  //Updated Day List - Week

  const today: any = moment();
  const xAxisWeek: any = Array(7)
    .fill(7)
    .map(() => today.subtract(1, "d").format("MM/DD"));

  const xAxisNewValueWeek = xAxisWeek.reverse();

  //Updated Day List - Month

  const todayMonth: any = moment();
  const xAxisMonth: any = Array(30)
    .fill(30)
    .map(() => todayMonth.subtract(1, "d").format("MM/DD"));

  const xAxisNewValueMonth = xAxisMonth.reverse();

  // Year

  let monthName: any = new Array(
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  );
  let currentDate: any = new Date();

  let result: any = [];
  currentDate.setDate(1);
  for (let i = 0; i <= 11; i++) {
    result.push(
      monthName[currentDate.getMonth()]
      // +
      //   " " +
      //   currentDate.getFullYear().toString().substr(-2)
    );
    currentDate.setMonth(currentDate.getMonth() - 1);
  }
  const xAxisNewValue: any = result.reverse();
  const xAxisValueYear: any = xAxisNewValue.slice(
    xAxisNewValue.length - 12,
    xAxisNewValue.length
  );

  const [xAxisChartDataGraph1, setXAxisChartDataGraph1] =
    useState<any>(xAxisNewValueWeek);
  const [xAxisChartDataGraph2, setXAxisChartDataGraph2] =
    useState<any>(xAxisNewValueWeek);
  const [xAxisChartDataGraph3, setXAxisChartDataGraph3] =
    useState<any>(xAxisNewValueWeek);
  const [xAxisChartDataGraph4, setXAxisChartDataGraph4] =
    useState<any>(xAxisNewValueWeek);

  const [xAxisIntervalGraph1, setXAxisIntervalGraph1] = useState(0);
  const [xAxisIntervalGraph2, setXAxisIntervalGraph2] = useState(0);
  const [xAxisIntervalGraph3, setXAxisIntervalGraph3] = useState(0);
  const [xAxisIntervalGraph4, setXAxisIntervalGraph4] = useState(0);

  const selectList = [
    { label: "Real Time", value: "Real Time" },
    { label: "Day", value: "Day" },
    { label: "Week", value: "Week" },
    { label: "Month", value: "Month" },
    { label: "Year", value: "Year" },
  ];
  const monthFomrat = "{value:%m/%e}";
  const dayFormat = "{value:%H:00}";
  const yearFormat = "{value:%b}";

  const [formatGraph1, setFormatGraph1] = useState(monthFomrat);
  const [formatGraph2, setFormatGraph2] = useState(monthFomrat);
  const [formatGraph3, setFormatGraph3] = useState(monthFomrat);
  const [formatGraph4, setFormatGraph4] = useState(monthFomrat);
  const [selectedRealTimeGraph, setSelectedRealTimeGraph] =
    useState("Real Time");

  const handleSelect = (val: string, graphName: string) => {
    if (graphName === "graph1") {
      switch (val) {
        case "Real Time":
          setSelectedRealTimeGraph("Real Time");
          break;
        case "Day":
          setFormatGraph1(dayFormat);
          setTempratureGraphDataStateUpdates(
            tempratureGraphData?.day?.analyticsData
          );
          setXAxisChartDataGraph1(xAxisNewtime);
          setXAxisIntervalGraph1(4);
          setSelectedRealTimeGraph("");

          break;
        case "Week":
          setFormatGraph1(monthFomrat);
          setTempratureGraphDataStateUpdates(
            tempratureGraphData?.weekly?.analyticsData
          );
          setXAxisChartDataGraph1(xAxisNewValueWeek);
          setXAxisIntervalGraph1(0);
          setSelectedRealTimeGraph("");
          break;
        case "Month":
          setFormatGraph1(monthFomrat);
          setTempratureGraphDataStateUpdates(
            tempratureGraphData?.monthly?.analyticsData
          );
          setXAxisChartDataGraph1(xAxisNewValueMonth);
          setXAxisIntervalGraph1(5);
          setSelectedRealTimeGraph("");
          break;
        case "Year":
          setFormatGraph1(yearFormat);
          setTempratureGraphDataStateUpdates(
            tempratureGraphData?.yearly?.analyticsData
          );
          setXAxisChartDataGraph1(xAxisValueYear);
          setXAxisIntervalGraph1(1);
          setSelectedRealTimeGraph("");
          break;
        default:
          setFormatGraph1(dayFormat);
          setTempratureGraphDataStateUpdates(
            tempratureGraphData?.day?.analyticsData
          );
          break;
      }
    }
    if (graphName === "graph2") {
      switch (val) {
        case "Day":
          setFormatGraph2(dayFormat);
          setHumidityGraphDataStateUpdates(
            humidityGraphData?.day?.analyticsData
          );
          setXAxisChartDataGraph2(xAxisNewtime);
          setXAxisIntervalGraph2(4);
          break;
        case "Week":
          setFormatGraph2(monthFomrat);
          setHumidityGraphDataStateUpdates(
            humidityGraphData?.weekly?.analyticsData
          );
          setXAxisChartDataGraph2(xAxisNewValueWeek);
          setXAxisIntervalGraph2(0);
          break;
        case "Month":
          setFormatGraph2(monthFomrat);
          setHumidityGraphDataStateUpdates(
            humidityGraphData?.monthly?.analyticsData
          );
          setXAxisChartDataGraph2(xAxisNewValueMonth);
          setXAxisIntervalGraph2(5);
          break;
        case "Year":
          setFormatGraph2(yearFormat);
          setHumidityGraphDataStateUpdates(
            humidityGraphData?.yearly?.analyticsData
          );
          setXAxisChartDataGraph2(xAxisValueYear);
          setXAxisIntervalGraph2(1);
          break;
        default:
          setFormatGraph2(dayFormat);
          setHumidityGraphDataStateUpdates(
            humidityGraphData?.day?.analyticsData
          );
          break;
      }
    }
    if (graphName === "graph3") {
      switch (val) {
        case "Day":
          setFormatGraph3(dayFormat);
          setHoursOfOperationGraphDataStateUpdates(
            hoursOfOperationGraphData?.day?.analyticsData
          );
          setXAxisChartDataGraph3(xAxisNewtime);
          setXAxisIntervalGraph3(4);
          break;
        case "Week":
          setFormatGraph3(monthFomrat);
          setHoursOfOperationGraphDataStateUpdates(
            hoursOfOperationGraphData?.weekly?.analyticsData
          );
          setXAxisChartDataGraph3(xAxisNewValueWeek);
          setXAxisIntervalGraph3(0);
          break;
        case "Month":
          setFormatGraph3(monthFomrat);
          setHoursOfOperationGraphDataStateUpdates(
            hoursOfOperationGraphData?.monthly?.analyticsData
          );
          setXAxisChartDataGraph3(xAxisNewValueMonth);
          setXAxisIntervalGraph3(5);
          break;
        case "Year":
          setFormatGraph3(yearFormat);
          setHoursOfOperationGraphDataStateUpdates(
            hoursOfOperationGraphData?.yearly?.analyticsData
          );
          setXAxisChartDataGraph3(xAxisValueYear);
          setXAxisIntervalGraph3(1);
          break;
        default:
          setFormatGraph3(dayFormat);
          setHoursOfOperationGraphDataStateUpdates(
            hoursOfOperationGraphData?.day?.analyticsData
          );
          break;
      }
    }
    if (graphName === "graph4") {
      switch (val) {
        case "Day":
          setFormatGraph4(dayFormat);
          setTripsGraphDataStateUpdates(tripsGraphData?.day?.analyticsData);
          setXAxisChartDataGraph4(xAxisNewtime);
          setXAxisIntervalGraph4(4);
          break;
        case "Week":
          setFormatGraph4(monthFomrat);
          setTripsGraphDataStateUpdates(tripsGraphData?.weekly?.analyticsData);
          setXAxisChartDataGraph4(xAxisNewValueWeek);
          setXAxisIntervalGraph4(0);
          break;
        case "Month":
          setFormatGraph4(monthFomrat);
          setTripsGraphDataStateUpdates(tripsGraphData?.monthly?.analyticsData);
          setXAxisChartDataGraph4(xAxisNewValueMonth);
          setXAxisIntervalGraph4(5);
          break;
        case "Year":
          setFormatGraph4(yearFormat);
          setTripsGraphDataStateUpdates(tripsGraphData?.yearly?.analyticsData);
          setXAxisChartDataGraph4(xAxisValueYear);
          setXAxisIntervalGraph4(1);
          break;
        default:
          setFormatGraph4(dayFormat);
          setTripsGraphDataStateUpdates(tripsGraphData?.day?.analyticsData);
          break;
      }
    }
  };

  const [searchIsOpen, setSearchIsOpen] = useState<boolean>(false);

  const handleSearchIsOpen = () => {
    setSearchIsOpen(!searchIsOpen);
    handleSearch("");
  };

  const handleSearch = (searchValue: string) => {
    let filteredData =
      alertDataList &&
      alertDataList?.filter((notification: any) => {
        let timeStamp = new Date(notification?.timeStamp)
          .toLocaleString()
          .split(",");

        return (
          notification.title
            .toLowerCase()
            .includes(searchValue?.toLowerCase()) ||
          timeStamp[0]?.includes(searchValue) ||
          notification?.area
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          notification?.eqipment
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        );
      });
    setNewSearchValue(filteredData);
  };

  return (
    <>
      <DialogWrapper open={open}>
        <div className={headerStyle}>
          <Tabs
            initialIndex={0}
            tabsList={tabsList}
            handleTabs={handleTabs}
            dashboardNotificationClassName={customNotificationTabs}
          />
        </div>

        <div>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 15,
              top: 12,
              color: appTheme.palette.infoDialogueComponent.colorWhite,
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Grid container>
          <Grid item xs={12} className={subListRow}>
            <InfoSubList
              highlighted={"safetyScore"}
              infoSubList={infoSubList}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
            <Grid container className={subListSection}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <div className={tripsSection1}>
                      <div className={incomeCurrentSection}>
                        <p className={incomeText}>{graphOneTitle}</p>
                        <div
                        // className={customSelectButton}
                        >
                          <Select
                            selectList={selectList}
                            handleSelect={handleSelect}
                            customWidth={"98px"}
                            customHeight={"30px"}
                            graphName={"graph1"}
                            selectedAnalyticsTitle={
                              equipmentViewDetailsItem?.title
                            }
                            graphTitle={graphOneTitle}
                            selectedRealTimeGraph={
                              (equipmentViewDetailsItem?.title ===
                                "Equipment#1" ||
                                equipmentViewDetailsItem?.title ===
                                  "Equipment#2" ||
                                equipmentViewDetailsItem?.title ===
                                  "Equipment#3") &&
                              graphOneTitle === "Temperature"
                                ? selectedRealTimeGraph
                                : ""
                            }
                          />
                        </div>
                      </div>
                      {(equipmentViewDetailsItem?.title === "Equipment#1" ||
                        equipmentViewDetailsItem?.title === "Equipment#2" ||
                        equipmentViewDetailsItem?.title === "Equipment#3") &&
                      selectedRealTimeGraph === "Real Time" ? (
                        <RealTimeChart
                          minWidth={chartWidth}
                          height={chartHeight}
                          pageName={"infoDialogue"}
                        />
                      ) : (
                        <Chart
                          type={
                            graphOneTitle === "Hours of Operation" ||
                            graphOneTitle === "Operating Hours"
                              ? "column"
                              : "spline"
                          }
                          minWidth={chartWidth}
                          height={chartHeight}
                          dataPoints={updatedTempratureGraphData}
                          format={formatGraph1}
                          toolTipShared={false}
                          splineWidth={2}
                          graphSequence={"graph1"}
                          tooltipShow={true}
                          isCrosshair={true}
                          dataLabels={false}
                          tabIdentity={"drivers"}
                          xAxisArray={xAxisChartDataGraph1}
                          xAxisInterval={xAxisIntervalGraph1}
                          pageName={"infoDialogue"}
                          graphTitle={graphOneTitle}
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={tripsSection2}>
                      <div className={incomeCurrentSection}>
                        <p className={incomeText}>{graphTwoTitle}</p>
                        <div
                        // className={customSelectButton}
                        >
                          <Select
                            selectList={selectList}
                            handleSelect={handleSelect}
                            customWidth={"98px"}
                            customHeight={"30px"}
                            graphName={"graph2"}
                          />
                        </div>
                      </div>

                      <Chart
                        type={
                          graphTwoTitle === "Hours of Operation" ||
                          graphTwoTitle === "Operating Hours"
                            ? "column"
                            : "spline"
                        }
                        minWidth={chartWidth}
                        height={chartHeight}
                        dataPoints={updatedHumidityGraphData}
                        format={formatGraph2}
                        toolTipShared={false}
                        splineWidth={2}
                        graphSequence={"graph2"}
                        tooltipShow={true}
                        isCrosshair={true}
                        dataLabels={false}
                        tabIdentity={"drivers"}
                        xAxisArray={xAxisChartDataGraph2}
                        xAxisInterval={xAxisIntervalGraph2}
                        pageName={"infoDialogue"}
                        graphTitle={graphTwoTitle}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={tripsSection}>
                      <div className={incomeCurrentSection}>
                        <p className={incomeText}>{graphThreeTitle}</p>
                        <div
                        // className={customSelectButton}
                        >
                          <Select
                            selectList={selectList}
                            handleSelect={handleSelect}
                            customWidth={"98px"}
                            customHeight={"30px"}
                            graphName={"graph3"}
                          />
                        </div>
                      </div>

                      <Chart
                        type={
                          graphThreeTitle === "Hours of Operation" ||
                          graphThreeTitle === "Operating Hours"
                            ? "column"
                            : "spline"
                        }
                        minWidth={chartWidth}
                        height={chartHeight}
                        dataPoints={updatedHoursOfOperationGraphData}
                        format={formatGraph3}
                        toolTipShared={false}
                        splineWidth={2}
                        graphSequence={"graph3"}
                        tooltipShow={true}
                        isCrosshair={true}
                        dataLabels={false}
                        tabIdentity={"drivers"}
                        xAxisArray={xAxisChartDataGraph3}
                        xAxisInterval={xAxisIntervalGraph3}
                        pageName={"infoDialogue"}
                        graphTitle={graphThreeTitle}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={tripsSection}>
                      <div className={incomeCurrentSection}>
                        <p className={incomeText}>{graphFourTitle}</p>
                        <div
                        // className={customSelectButton}
                        >
                          <Select
                            selectList={selectList}
                            handleSelect={handleSelect}
                            customWidth={"98px"}
                            customHeight={"30px"}
                            graphName={"graph4"}
                          />
                        </div>
                      </div>

                      <Chart
                        type={
                          graphFourTitle === "Hours of Operation" ||
                          graphFourTitle === "Operating Hours"
                            ? "column"
                            : "spline"
                        }
                        minWidth={chartWidth}
                        height={chartHeight}
                        dataPoints={updatedTripsGraphData}
                        format={formatGraph4}
                        toolTipShared={false}
                        splineWidth={2}
                        graphSequence={"graph4"}
                        tooltipShow={true}
                        isCrosshair={true}
                        dataLabels={false}
                        tabIdentity={"drivers"}
                        xAxisArray={xAxisChartDataGraph4}
                        xAxisInterval={xAxisIntervalGraph4}
                        pageName={"infoDialogue"}
                        graphTitle={graphFourTitle}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
            <div className={`${violationSection} height-adjustment`}>
              <Grid container>
                {searchIsOpen === false ? (
                  <Grid item xs={9.5} className={notificationHeading}>
                    {infoDialogueNotifications}
                  </Grid>
                ) : null}
                {searchIsOpen && (
                  <Grid item xs={9.5} className={notificationSearchHeading}>
                    {searchIsOpen && (
                      <SearchBox
                        searchInput={searchClass}
                        placeHolder={"Search"}
                        handleSearch={handleSearch}
                        searchIsOpen={searchIsOpen}
                        fontColor={
                          appTheme?.palette?.searchBoxElement?.colorWhite
                        }
                      />
                    )}
                  </Grid>
                )}

                <Grid item xs={2.5} className={notificationHeading}>
                  <div className={notificationSearchIcon}>
                    <img
                      src={!searchIsOpen ? SearchIconInfo : CloseIconWhite}
                      onClick={handleSearchIsOpen}
                      width={!searchIsOpen ? 25 : 15}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} className={notificationTabHeading}>
                  <div className={chipButtonSection}>
                    <Tabs
                      initialIndex={chipTabIndex}
                      tabsList={chipTabsList}
                      handleTabs={handleChipTabs}
                      dashboardNotificationClassName={
                        customNotificationChipTabs
                      }
                    />
                    {/* {chipButtons && chipButtons?.length > 0
                      ? chipButtons.map((item: any, index: number) => {
                          if (index <= 2) {
                            return (
                              <ChipButton
                                key={index}
                                data={item}
                                index={index}
                                handleClick={handleChipButton}
                              />
                            );
                          }
                        })
                      : null} */}
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  className={`${notificationList} notificationListHeight`}
                >
                  {newSearchValue && newSearchValue?.length > 0 ? (
                    newSearchValue.map((item: any, index: number) => {
                      return (
                        <InfoAlertListItem
                          key={index}
                          data={item}
                          index={index}
                        />
                      );
                    })
                  ) : (
                    <div>
                      <span style={{ padding: 10, color: "gray" }}>
                        No Result Found
                      </span>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </DialogWrapper>
    </>
  );
};

export default InfoDialog;
