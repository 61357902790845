import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";

const RadioButton = (props: any) => {
  const { options, selectedValue, handleRadioSelection, disabled } = props;

  return (
    <FormControl>
      <RadioGroup
        row
        name={selectedValue}
        value={selectedValue !== undefined && selectedValue}
        onChange={handleRadioSelection}
      >
        {options?.map((item: any) => {
          return (
            <FormControlLabel
              label={item.label}
              key={item.id}
              value={item.value}
              control={<Radio />}
              disabled={disabled}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButton;
