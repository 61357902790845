export default {
  title: "Hello Infinite English",
  profile: "Profile English",
  hello: "Hello",
  changeLang:"Change Language",
  changeTheme:"Change Theme",
 
  infoDialogueNotifications: "Alerts",
  infoSubListTemprature: "Temprature",
  infoSubListHumidity: "Humidity",
  infoSubListOprHrs: "Opr.Hrs",
  infoSubListPowerUsage: "Power Usage",
  infoSubListVibration: "Vibration",
  infoSubListPressure: "Pressure",
  infoSubListPredictiveMaintenance: "Predictive Maintenance",
  infoSubListSafetyInfractions: "Safety Infractions",
  infoSubListBatteryLevel: "Battery Level",
  infoSubListTripsRemaining: "Trips Remaining",
  chipButtonEvents: "Events",
  chipButtonIncidents: "Incidents",
  chipButtonOprAlerts: "Opr.Alerts",
  infoTabsListDetails: "DETAILS",
  infoTabsListGeofence: "GEOFENCE",
  infoGraphTitleHoursOfOperation: "Hours Of Operation",
  infoGraphTitleTrips: "Trips",
  
  dashboardListName: "Equipment",
  search: "Search",
  noResult: "No Result Found",
  itemIconList1: "Temperature",
  itemIconList2: "Humidity",
  itemIconList3: "Opr. Hours",
  itemIconList4: "Power Usage",
  itemListButton: "View Detail",
  dashboardTab: "Dashboard",
  alertsTab: "Alerts",
  analyticsTab: "Analytics",
  configurationTab: "Configuration",
  logout: "Logout",
  infoIconTitle1: "Number Of Pallets",
  infoIconTitle2: "Energy Consumed",
  infoIconTitle3: "Backup Power",
  infoIconTitle4: "Production Efficiency",
  infoIconTitle5: "Hours of Operation",
  infoIconTitle6: "Workers",
  infoIconTitle7: "Safety Infractions",
  poweredBy: "Powered by",
  copyRight: "© 2023. All Rights Reserved"
};
