import React, { Fragment, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import theme from "../../theme/theme";
import useStyles from "./styles";
import AnalyticsGraphItems from "components/AnalyticsGraphItems";
import AnalyticsList from "components/AnalyticsList";
import QcomAnalytics from "../../mockdata/qcomAnalytics";

const AnalyticsContainer: React.FC = (props: any) => {
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);

  const { rootContainer, notificationList } = useStyles(appTheme);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  const analyticsListsItems : any = QcomAnalytics?.analytics;

  const [activeListItemIndex,setActiveListItemIndex ] = useState<number | null>(null)

  const [selectedAnalyticListItem,setSelectedAnalyticListItem ] = useState<any>(QcomAnalytics?.analytics[0])

  

  const analyticsCategoryLists : any = selectedAnalyticListItem?.infoList;
  

 

  const handleSelectedAnalyticList = (item : any)=>{
  
    setSelectedAnalyticListItem(item)
  }

 


  
  

  

 

  return (
    <Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container className={rootContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={4}
            xl={3}
            className={notificationList}
          >
            <AnalyticsList
              analyticsListsItems={analyticsListsItems}
              handleSelectedAnalyticList={handleSelectedAnalyticList}
              setActiveListItemIndex={setActiveListItemIndex}
              activeListItemIndex={activeListItemIndex}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={9} lg={8} xl={9}>
            <AnalyticsGraphItems
              analyticsCategoryLists={analyticsCategoryLists}
              selectedAnalyticListItem={selectedAnalyticListItem}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default AnalyticsContainer;
