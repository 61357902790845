import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import Icon from "components/Icon";

export const RootContainer = styled("div")`
  flex: 1;
  position: relative;
`;

export const MapWrapper = styled("div")`
  position: relative;
  flex: 1;
  margin-bottom: 50px;

  .contentClass {
  }
`;

// ${({constantSide}) => constantSide === "height"
//     ?
//     `height: 100%;
//     width: auto;`
//     :
//     `width: 100%;
//     height: auto;`
//   }

export const MapImage = styled("img")<{ constantSide?: any }>`
  width: 100%;
  height: auto;
  max-height: ${({ constantSide }) => constantSide}px;
`;

export const ToolBarContainer = styled("div")`
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1200;
`;

export const ToolIconWrapper = styled("div")`
  padding: 0;
  margin-bottom: 15px;
`;

export const ToolIconContainer = styled("div")`
  width: 36px;
  height: 36px;
  border-radius: 36px;
  background-color: #4d6994;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const MarkerContainer = styled("div")<{
  x: number;
  y: number;
  currentScale: number;
}>`
  position: absolute;
  top: ${({ y }) => y}px;
  left: ${({ x }) => x}px;
  transform: translate(-50%, -100%) scale(${({ currentScale }) => currentScale});
  z-index: 70000;
`;

export const MarkerCloseButtonContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const MarkerCallout = styled("div")<{
  x: number;
  y: number;
  currentScale: number;
  placement: string;
  verticalPlacement?: string;
}>`
  position: absolute;
  top: ${({ y }) => y - 10}px;
  left: ${({ x }) => x + 20}px;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(7.5px);
  transform: ${({ verticalPlacement }) =>
      verticalPlacement === "top"
        ? "translateY(-100%)"
        : verticalPlacement === "bottom"
        ? "translateY(-25%)"
        : "translateY(-65%)"}
    ${({ placement }) => (placement === "left" ? "translateX(-110%)" : "")}
    scale(${({ currentScale }) => currentScale});
  transform-origin: ${({ placement }) =>
    placement === "left" ? "right" : "left"};
  z-index: 111000;
  border-radius: 5px;
  min-width: 450px;

  &:after {
    visibility: ${({ placement }) =>
      placement === "right" ? "visible" : "hidden"};
    content: "";
    position: absolute;
    top: ${({ verticalPlacement }) =>
      verticalPlacement === "top"
        ? "90%"
        : verticalPlacement === "bottom"
        ? "10%"
        : "50%"};
    right: 100%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent rgba(0, 0, 0, 0.8) transparent transparent;
    transform: translateY(-50%);
  }

  @media screen and (max-width: 1336px) {
    transform: ${({ verticalPlacement }) =>
      verticalPlacement === "top"
        ? "translateY(-90%)"
        : verticalPlacement === "bottom"
        ? "translateY(-25%)"
        : "translateY(-65%)"}
    ${({ placement }) => (placement === "left" ? "translateX(-110%)" : "")}
    scale(0.7);
  }

  &:before {
    visibility: ${({ placement }) =>
      placement === "left" ? "visible" : "hidden"};
    content: "";
    position: absolute;
    top: ${({ verticalPlacement }) =>
      verticalPlacement === "top"
        ? "90%"
        : verticalPlacement === "bottom"
        ? "10%"
        : "50%"};
    left: 100%;
    margin-left: 0px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent rgba(0, 0, 0, 0.8);
    transform: translateY(-50%);
  }
`;

export const CalloutCloseButton = styled("div")`
  color: #fff;
  cursor: pointer;
  height: 25px;
  border-radius: 25px;
`;

export const MarkerBadge = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff817b;
  width: 27px;
  height: 27px;
  border-radius: 27px;
  position: absolute;
  top: -10px;
  right: -5px;
  transform: scale(0.8);
  z-index: 10000;
`;

export const CalloutContent = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  * {
    color: #fff;
  }
`;

export const CalloutHeaderContainer = styled("div")`
  display: flex;
  align-items: flex-start;
`;

export const CalloutHeaderSection = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const CalloutHeaderAlertButtonContainer = styled("div")`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const CalloutHeaderAlertButton = styled("img")`
  margin-left: 10px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.2);
  }
`;

export const CalloutAlertText = styled("div")`
  /* font-family: "Qualcomm-Next"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: -3px;
  margin-bottom: 10px;
  color: #bbbbbb;
  letter-spacing: 0.5px;
`;

export const CalloutTitle = styled("div")`
  min-width: 300px;
  white-space: nowrap;
  font-family: "QualcommNext-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
  color: #ffffff;
  letter-spacing: 0.5px;
`;

export const AlertEquipmentName = styled(CalloutTitle)`
  font-size: 15px;
  margin-bottom: 0px;
  letter-spacing: 1px;
`;

export const VerticalSpace = styled("div")<{ count: number }>`
  height: ${({ count }) => count * 5}px;
`;
export const CalloutSubtitle = styled("div")`
  font-family: "QualcommNext-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #8693a8;
  display: flex;
  width: max-content;
  letter-spacing: 0.5px;
`;

export const CalloutBody = styled("div")`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 30px 10px 0px;
`;

export const CalloutBodyContainer = styled("div")`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 20px;

  &:last-child {
    margin-right: 0px;
  }
`;

export const CalloutBodyIconContainer = styled("div")`
  font-family: "QualcommNext-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 19px;
  color: #ffffff;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

export const CalloutBodyLabelContainer = styled("div")`
  font-family: "QualcommNext-Thin";
  font-style: normal;
  font-size: 12px;
  line-height: 19px;
  width: max-content;
  color: #bcbcbc;
`;

export const Marker = styled(Icon)`
  position: relative;
  cursor: pointer;
  transform-origin: bottom center;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  margin: 0;
  padding: 0;
  z-index: 100;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  svg {
    margin: 0;
    padding: 0;
  }

  &:hover {
    transform: scale(1.25);
  }
`;

export const Divider = styled("div")`
  width: 100%;
  opacity: 0.1;
  height: 1px;
  background-color: #ffffff;
`;

export const CalloutFooter = styled("div")`
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const DetailsButton = styled("div")`
  background-color: #3253dc;
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  /* font-family: "Qualcomm Next"; */
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  padding: 8px 18px;
  text-transform: capitalize;

  &:hover {
    background-color: #3253dc;
  }
`;

export const DateText = styled(CalloutSubtitle)`
  margin: 0;
`;

export const CalloutBodyIcon = styled("img")`
  margin-right: 5px;
`;
