import { makeStyles } from "@mui/styles";
import muiTheme from "../../theme/muiTheme";

const useStyles = makeStyles({
  rootContainer: (props: any) => ({
    background: "#FFFFFF",
    border: " 0.5px solid #97AAED",
    borderRadius: "5px",
    padding: "15px",
    marginBottom: "15px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [muiTheme.breakpoints.down(1537)]: {
      padding: "10px",
    },
  }),
  listContainer: (props: any) => ({
    background: "#E5E9FD",
    border: "0.5px solid #97AAED",
    padding: "10px",
    marginBottom: "10px",
  }),
  listitem: (props: any) => ({
    background: "#FFFFFF",
    border: " 0.5px solid #6173B3",
    borderRadius: "5px",
    padding: "15px",
    marginBottom: "10px",
    cursor: "pointer",
    [muiTheme.breakpoints.down(1537)]: {
      padding: "10px",
    },
  }),
  itemListTitle: (props: any) => ({
    fontSize: " 16px",
    lineHeight: "19px",
    fontFamily: "QualcommNext-Regular",
    [muiTheme.breakpoints.down(1537)]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  }),
});
export default useStyles;
