const dashBoardEquipments = {
  pallets: "96/120",
  energyConsumed: "51.6 Kwh",
  backupPower: "100%",
  productionEfficiency: "92%",
  operationHours: "10 Hrs",
  workers: "16",
  safetyInfractions: "16",
  equipments: [
    {
      category: "forklift",
      id: 1,
      title: "Autonomous ForkLift#1",
      area: "Distribution Area, Zone 1",
      temparature: "68",
      tripsRemaining: "80",
      oprHours: "4",
      charge: "80",
      inCharging: false,
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 1190,
        y: 193,
      },
    },
    {
      category: "forklift",
      id: 11,
      title: "Autonomous ForkLift#2",
      area: "Distribution Area, Zone 1",
      temparature: "48",
      tripsRemaining: "30",
      oprHours: "6",
      charge: "75",
      inCharging: false,
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 1100,
        y: 200,
      },
    },
    {
      category: "forklift",
      id: 12,
      title: "Autonomous ForkLift#3",
      area: "Distribution Area, Zone 1",
      temparature: "38",
      tripsRemaining: "40",
      oprHours: "8",
      charge: "55",
      inCharging: false,
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 1060,
        y: 140,
      },
    },
    {
      category: "forklift",
      id: 13,
      title: "Autonomous ForkLift#4",
      area: "Distribution Area, Zone 1",
      temparature: "58",
      tripsRemaining: "48",
      oprHours: "7",
      charge: "62",
      inCharging: true,
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 1300,
        y: 130,
      },
    },
    {
      category: "forklift",
      id: 14,
      title: "Autonomous ForkLift#5",
      area: "Distribution Area, Zone 1",
      temparature: "43",
      tripsRemaining: "63",
      oprHours: "8",
      charge: "52",
      inCharging: false,
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 1010,
        y: 200,
      },
    },
    {
      category: "aiCamera",
      id: 2,
      title: "AI Camera #23",
      area: "Packaging Area, Zone 1",
      temparature: "68",
      humidity: "80",
      rejects: "4",
      vibration: "80",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 462,
        y: 190,
      },
    },
    {
      category: "aiCamera",
      id: 21,
      title: "AI Camera #24",
      area: "Inspection Area, Zone 2",
      temparature: "48",
      humidity: "75",
      rejects: "7",
      vibration: "60",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 50,
        y: 450,
      },
    },
    {
      category: "robotArm",
      id: 3,
      title: "Robotic Arm Packager #13",
      area: "Packaging Area, Zone 1",
      temparature: "68",
      humidity: "80",
      daysMaintanence: "4",
      vibration: "80",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 230,
        y: 240,
      },
    },
    {
      category: "robotArm",
      id: 31,
      title: "Robotic Arm Packager #14",
      area: "Packaging Area, Zone 1",
      temparature: "50",
      humidity: "70",
      daysMaintanence: "6",
      vibration: "50",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 340,
        y: 255,
      },
    },
    {
      category: "robotArm",
      id: 32,
      title: "Robotic Arm Packager #15",
      area: "Palleting Area, Zone 1",
      temparature: "65",
      humidity: "85",
      daysMaintanence: "5",
      vibration: "70",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 775,
        y: 220,
      },
    },
    {
      category: "conveyerBelt",
      id: 4,
      title: "Conveyor Belt#02",
      area: "Production Area, Zone 2",
      temparature: "68",
      beltSpeed: "80",
      tension: "4",
      vibration: "80",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 260,
        y: 560,
      },
    },
    {
      category: "conveyerBelt",
      id: 41,
      title: "Conveyor Belt#03",
      area: "Production Area, Zone 2",
      temparature: "58",
      beltSpeed: "60",
      tension: "7",
      vibration: "50",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 490,
        y: 655,
      },
    },
    {
      category: "pallet",
      id: 5,
      title: "Pallet#2",
      area: "Palleting Area, Zone 1",
      temparature: "68",
      palletsCompleted: "80",
      daysMaintanence: "4",
      vibration: "80",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 930,
        y: 220,
      },
    },
    {
      category: "packager",
      id: 6,
      title: "Packager05",
      area: "Packaging Area, Zone 1",
      temparature: "68",
      packagesCompleted: "80",
      daysMaintanence: "4",
      vibration: "80",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 535,
        y: 255,
      },
    },
    {
      category: "amr",
      id: 7,
      title: "AMR#23",
      area: "Distribution Area, Zone 1",
      temparature: "68",
      tripsRemaining: "80",
      oprHours: "4",
      charge: "80",
      inCharging: false,
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 1250,
        y: 165,
      },
    },
    {
      category: "amr",
      id: 71,
      title: "AMR#24",
      area: "Distribution Area, Zone 2",
      temparature: "55",
      tripsRemaining: "40",
      oprHours: "7",
      charge: "60",
      inCharging: false,
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 1300,
        y: 220,
      },
    },
    {
      category: "amr",
      id: 72,
      title: "AMR#25",
      area: "Inspection Area, Zone 1",
      temparature: "65",
      tripsRemaining: "50",
      oprHours: "7",
      charge: "50",
      inCharging: false,
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 5,
        y: 360,
      },
    },
    {
      category: "amr",
      id: 73,
      title: "AMR#26",
      area: "Packaging Area, Zone 1",
      temparature: "62",
      tripsRemaining: "58",
      oprHours: "8",
      charge: "40",
      inCharging: false,
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 550,
        y: 160,
      },
    },
    {
      category: "equipment",
      id: 8,
      title: "Equipment#1",
      area: "Production Area, Zone 2",
      temparature: "58",
      humidity: "60",
      pressure: "6",
      vibration: "60",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 960,
        y: 760,
      },
    },
    {
      category: "equipment-2",
      id: 77,
      title: "Equipment#2",
      area: "Production Area, Zone 2",
      temparature: "68",
      humidity: "80",
      pressure: "4",
      vibration: "80",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 1100,
        y: 760,
      },
    },
    {
      category: "equipment-3",
      id: 81,
      title: "Equipment#3",
      area: "Production Area, Zone 2",
      temparature: "48",
      humidity: "60",
      pressure: "8",
      vibration: "50",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 1240,
        y: 760,
      },
    },

    {
      category: "workers",
      id: 9,
      title: "Worker #1",
      area: "Packaging Area, Zone 1",
      safetyInfration: "68",
      oprHours: "8",
      safetyScore: "80",
      productivityScore: "80",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 300,
        y: 185,
      },
    },
    {
      category: "workers",
      id: 91,
      title: "Worker #2",
      area: "Packaging Area, Zone 1",
      safetyInfration: "55",
      oprHours: "14",
      safetyScore: "65",
      productivityScore: "60",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 590,
        y: 230,
      },
    },

    {
      category: "workers",
      id: 92,
      title: "Worker #3",
      area: "Inspection Area, Zone 2",
      safetyInfration: "78",
      oprHours: "20",
      safetyScore: "75",
      productivityScore: "55",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 80,
        y: 600,
      },
    },
    {
      category: "workers",
      id: 93,
      title: "Worker #4",
      area: "Inspection Area, Zone 2",
      safetyInfration: "68",
      oprHours: "8",
      safetyScore: "75",
      productivityScore: "80",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 160,
        y: 690,
      },
    },
    {
      category: "workers",
      id: 94,
      title: "Worker #5",
      area: "Inspection Area, Zone 2",
      safetyInfration: "55",
      oprHours: "6",
      safetyScore: "68",
      productivityScore: "60",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 260,
        y: 705,
      },
    },
    {
      category: "workers",
      id: 95,
      title: "Worker #6",
      area: "Production Area, Zone 2",
      safetyInfration: "68",
      oprHours: "8",
      safetyScore: "78",
      productivityScore: "80",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 620,
        y: 730,
      },
    },
    {
      category: "workers",
      id: 96,
      title: "Worker #7",
      area: "Production Area, Zone 2",
      safetyInfration: "43",
      oprHours: "7",
      safetyScore: "76",
      productivityScore: "65",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 690,
        y: 670,
      },
    },
    {
      category: "workers",
      id: 97,
      title: "Worker #8",
      area: "Production Area, Zone 2",
      safetyInfration: "58",
      oprHours: "8",
      safetyScore: "79",
      productivityScore: "62",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 730,
        y: 740,
      },
    },
    {
      category: "workers",
      id: 98,
      title: "Worker #9",
      area: "Production Area, Zone 2",
      safetyInfration: "40",
      oprHours: "6",
      safetyScore: "70",
      productivityScore: "80",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 1340,
        y: 540,
      },
    },
    {
      category: "workers",
      id: 99,
      title: "Worker #10",
      area: "Production Area, Zone 2",
      safetyInfration: "38",
      oprHours: "7",
      safetyScore: "68",
      productivityScore: "60",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 1100,
        y: 520,
      },
    },
    {
      category: "workers",
      id: 100,
      title: "Worker #11",
      area: "Production Area, Zone 2",
      safetyInfration: "68",
      oprHours: "8",
      safetyScore: "60",
      productivityScore: "80",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 1060,
        y: 560,
      },
    },
    {
      category: "workers",
      id: 101,
      title: "Worker #12",
      area: "Production Area, Zone 2",
      safetyInfration: "60",
      oprHours: "6",
      safetyScore: "60",
      productivityScore: "90",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 1190,
        y: 820,
      },
    },
    {
      category: "workers",
      id: 102,
      title: "Worker #13",
      area: "Production Area, Zone 2",
      safetyInfration: "68",
      oprHours: "8",
      safetyScore: "40",
      productivityScore: "89",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 1080,
        y: 820,
      },
    },
    {
      category: "workers",
      id: 103,
      title: "Worker #14",
      area: "Packaging Area, Zone 1",
      safetyInfration: "55",
      oprHours: "6",
      safetyScore: "80",
      productivityScore: "70",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 335,
        y: 450,
      },
    },
    {
      category: "workers",
      id: 104,
      title: "Worker #15",
      area: "Palleting Area, Zone 1",
      safetyInfration: "68",
      oprHours: "8",
      safetyScore: "65",
      productivityScore: "82",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 696,
        y: 360,
      },
    },
    {
      category: "workers",
      id: 105,
      title: "Worker #16",
      area: "Inspection Area, Zone 2",
      safetyInfration: "48",
      oprHours: "8",
      safetyScore: "60",
      productivityScore: "50",
      timeStamp: "12-15-2022 | 09:00 AM",
      location: {
        x: 110,
        y: 270,
      },
    },
  ],
};

export default dashBoardEquipments;
