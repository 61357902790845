import moment from "moment";
import { useState, useEffect } from "react";
import theme from "../../theme/theme";
import {
  RootContainer,
  ItemContainer,
  ItemValue,
  ItemLabel,
  VerticalSpace,
} from "./styles";

const InfoSubList: React.FC<any> = ({ highlighted, infoSubList, ...props }) => {
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);

  const [selectedTheme, setSelectedTheme] = useState(
    JSON.parse(localStorage.getItem("theme")!)
  );

  useEffect(() => {
    switch (selectedTheme) {
      case "red":
        setAppTheme(theme?.redTheme);
        break;
      case "green":
        setAppTheme(theme?.greenTheme);
        break;
      case "yellow":
        setAppTheme(theme?.yellowTheme);
        break;
      case "default":
        setAppTheme(theme?.defaultTheme);
        break;
      default:
        setAppTheme(theme?.defaultTheme);
        break;
    }
  }, [selectedTheme]);

  // const [items, setItems] = useState<any>([]);

  // useEffect(() => {
  //   const propItems: any = [];
  //   Object.keys(props).forEach((key) => {
  //     propItems.push({ key, value: props[key] });
  //   });

  //   setItems(propItems);
  // }, []);

  // const toTitleCase = (testString: string) => {
  //   let titleCaseString = testString
  //     .replace(/(_|-)/g, " ")
  //     .trim()
  //     .replace(/\w\S*/g, function (str) {
  //       return str.charAt(0).toUpperCase() + str.substr(1);
  //     })
  //     .replace(/([a-z])([A-Z])/g, "$1 $2")
  //     .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");

  //   return titleCaseString;
  // };

  return (
    <RootContainer>
      {infoSubList?.map((item: any) => {
        return (
          <ItemContainer
            paletteColor={appTheme?.palette?.infoSubListElement?.lightShadeGray}
            key={item?.title + item?.value}
          >
            <ItemValue
              paletteColor={appTheme?.palette?.infoSubListElement?.colorWhite}
            >
              {item?.title === "Predictive Maintenance" ? moment().add(5, "d").format("DD-MM-YYYY") : item.value}
            </ItemValue>
            <ItemLabel
              paletteColor={
                appTheme?.palette?.infoSubListElement?.lightShadeGray1
              }
            >
              {item?.title}
            </ItemLabel>
          </ItemContainer>
        );
      })}
    </RootContainer>
  );
};

export default InfoSubList;
